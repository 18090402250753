import { plans } from './plans'

// Usuário pode receber mais convites, participar de mais equipes?
export function canMoreInvites(user) {
  let canMore = false
  const qtd = user.contas.length
  const plano = user.plano.toLocaleUpperCase()
  switch (plano) {
    case plans.planFree.title.toLocaleUpperCase():
      if (qtd < 2) canMore = true // A própria e mais 2 equipes
      break
    case plans.planAdm.title.toLocaleUpperCase():
      canMore = true
      break
    case plans.planBusiness.title.toLocaleUpperCase():
      canMore = false
      break
    case plans.planAdvanced.title.toLocaleUpperCase():
      canMore = false
      break
    case plans.planBroker.title.toLocaleUpperCase():
      if (qtd < 3) canMore = true
      break
    case plans.planUser.title.toLocaleUpperCase():
      if (qtd < 2) canMore = true
      break
    default:
      if (qtd <= 1) canMore = true
      break
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('canMoreInvites', canMore, plano, qtd)
  return canMore
}

// Usuário pode Entrar na Equipe
export function canUserJoinTeam(qtd, plano) {
  let canJoin = false
  switch (plano) {
    case plans.planAdm.title.toLocaleUpperCase():
      canJoin = true
      break
    case plans.planFree.title.toLocaleUpperCase():
      if (qtd < 2) canJoin = true
      break
    case plans.planBusiness.title.toLocaleUpperCase():
      if (qtd < 50) canJoin = true
      break
    case plans.planAdvanced.title.toLocaleUpperCase():
      if (qtd < 10) canJoin = true
      break
    default:
      canJoin = false
      break
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('canUserJoinTeam', canJoin, plano, qtd, plans.planAdvanced.title.toLocaleUpperCase())
  return canJoin
}

// Usuário pode Enviar mais Convites
export function canUserSendInvite(user, qtd) {
  let canSend = false
  const plano = user.plano.toLocaleUpperCase()
  switch (plano) {
    case plans.planFree.title.toLocaleUpperCase():
      if (qtd < 2) canSend = true
      break
    case plans.planAdm.title.toLocaleUpperCase():
      canSend = true
      break
    case plans.planBusiness.title.toLocaleUpperCase():
      if (qtd < 50) canSend = true
      break
    case plans.planAdvanced.title.toLocaleUpperCase():
      if (qtd < 10) canSend = true
      break
    default:
      canSend = false
      break
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('canSendInvite', canSend, plano, user)
  return canSend
}

// Usuário pode ser Operador de Outra equipe
export function canBeOperator(user) {
  let canBe = false
  const qtd = user.contas.length
  const plano = user.plano.toLocaleUpperCase()
  switch (plano) {
    case plans.planFree.title.toLocaleUpperCase():
      if (qtd < 2) canBe = true
      break
    case plans.planAdm.title.toLocaleUpperCase():
      canBe = true
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('plano', plano, qtd)
      break
    case plans.planBusiness.title.toLocaleUpperCase():
      canBe = false
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('plano', plano, qtd)
      break
    case plans.planAdvanced.title.toLocaleUpperCase():
      canBe = false
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('plano', plano, qtd)
      break
    case plans.planBroker.title.toLocaleUpperCase():
      if (qtd < 3) canBe = true
      break
    case plans.planUser.title.toLocaleUpperCase():
      if (qtd < 3) canBe = true
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('plano', plano, qtd)
      break
    default:
      canBe = false
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('plano', plano, qtd)
      break
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('canBe', canBe, plano, qtd)
  return canBe
}

// Usuário pode ter Equipe
export function canTeam(user) {
  let canTeam = false
  const plano = user.plano.toLocaleUpperCase()
  switch (plano) {
    case plans.planAdm.title.toLocaleUpperCase():
      canTeam = true
      break
    case plans.planBusiness.title.toLocaleUpperCase():
      canTeam = true
      break
    case plans.planAdvanced.title.toLocaleUpperCase():
      canTeam = true
      break
    case plans.planFree.title.toLocaleUpperCase():
      if (user.conta !== user.id) canTeam = false
      else canTeam = true
      break
    case plans.planBroker.title.toLocaleUpperCase():
      canTeam = false
      break
    case plans.planUser.title.toLocaleUpperCase():
      canTeam = false
      break

    default:
      canTeam = false
      break
  }
  return canTeam
}

// Usuário pode Editar Imóveis
export function canEdit(user) {
  // Usuário Plano User aguardando CONVITE não pode Editar
  if (user.plano === plans.planUser.title.toLocaleUpperCase() && user.rules[0] === plans.planAdm.rule.title) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('CanEdit', user, false)
    return false
  }
  else {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('CanEdit', user, user.permissions.includes('write'))
    return user.permissions.includes('write')
  }

}

export function canFilter(user){
   // Usuário Plano User aguardando CONVITE não pode Filtrar
  if (user.plano === plans.planUser.title.toLocaleUpperCase() && user.rules[0] === plans.planAdm.rule.title) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('CanEdit', user, false)
    return false
  }
  else return true
}