import { Box, Button, Container, Paper, Typography, Image, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Start.css';


const classes = require('./Styles')

export function Start() {

  let navigate = useNavigate()

  function handleOnClick() {
    navigate('/singin')
  }
  return (
    <Grid>
      <Grid item
        container
        direction="column"
        alignItems="center"
        // justifyContent="center"
        sx={{
          backgroundImage: 'url(./logo.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#1976d2',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        {/* <Grid item padding={5}>
          <Typography variant="h3" color={'white'}>
             
          </Typography>
        </Grid> */}
      </Grid>
    </Grid>

  );
}