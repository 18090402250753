import { situacaoTipo } from '../utils/tipos'

export function generatePrompt(place, user, cliente, tpMessage) {

  let corretor = user.nome
  let imobiliaria = user.equipe
  let prompt = ''
  let imovel = ''
  let identificacao = ''
  let caracteristicas = ''
  let investimento = ''


  if (place.empreendimento) { identificacao = identificacao + ' Empreendimento: ' + place.empreendimento + ' ' }
  if (place?.construtora) { identificacao = identificacao + ' Construtora: ' + place.construtora + ' ' }
  if (place?.blocos) { identificacao = identificacao + ' Torres: ' + place.blocos + ' ' }
  if (place?.andares) { identificacao = identificacao + ' Andares: ' + place.andares + ' ' }
  if (place?.endereco) { identificacao = identificacao + ' Endereço: ' + place.endereco + ' ' }
  if (place?.maxarea) { identificacao = identificacao + ' Metragens:' + place?.minarea + 'm2 até ' + place?.maxarea + 'm2 '}
  if (place?.quartosqtdmax) {
    if (place?.quartosqtdmax === place?.quartosqtdmin) {
      let _quartos = ' quarto'
      if (place?.quartosqtdmin > 1) { _quartos = _quartos + 's' }
      identificacao = identificacao + ' Dormitórios: ' + place.quartosqtdmin + _quartos + ' '
    } else {
      identificacao = identificacao + ' Dormitórios: ' + place.quartosqtdmin + ' até ' + place.quartosqtdmax + 'quartos ' 
    }
  }
  if (place?.situacao) { identificacao = identificacao + situacaoTipo(place.situacao) + ' ' }
  if (place?.entrega) { identificacao = identificacao + 'Entrega: ' + place?.entrega + ' ' }

  if (tpMessage === 'financeiro' || tpMessage === 'completo') {
    if (place?.vlrinicial) { investimento = investimento + ' Investimento de ' + place.vlrinicial + ' até ' + place.vlrfinal + ' aproximadamente '}
    if (place?.refvlrano) { investimento = investimento + ' Referência dos valores: ' + place?.refvlrmes + '/' + place?.refvlrano + ' ' }
    if (place?.renda) { investimento = investimento + ' Renda mínima para investimento: ' + place.renda + ' ' }
  }

  if (tpMessage === 'apresentacao' || tpMessage === 'completo') {

    if (place?.site) { identificacao = identificacao + ' Site: ' + place.site }

    caracteristicas = caracteristicas + ' Facilidades:\n'
    if (place?.academia) { caracteristicas = caracteristicas + ' Academia, ' }
    if (place?.cinema) { caracteristicas = caracteristicas + ' Cinema, ' }
    if (place?.churrasqueira) { caracteristicas = caracteristicas + ' Churrasqueira, ' }
    if (place?.espacokids) { caracteristicas = caracteristicas + ' Espaço Kids, ' }
    if (place?.esportebar) { caracteristicas = caracteristicas + ' Esporte Bar, ' }
    if (place?.gourmet) { caracteristicas = caracteristicas + ' Espaço Gourmet, ' }
    if (place?.lavanderia) { caracteristicas = caracteristicas + ' Lavanderia, ' }
    if (place?.marketplace) { caracteristicas = caracteristicas + ' Marketplace, ' }
    if (place?.petcare) { caracteristicas = caracteristicas + ' Petcare, ' }
    if (place?.petplace) { caracteristicas = caracteristicas + ' Petplace, ' }
    if (place?.piscina) { caracteristicas = caracteristicas + ' Piscina, ' }
    if (place?.playgroud) { caracteristicas = caracteristicas + ' Playgroud, ' }
    if (place?.qdesportiva) { caracteristicas = caracteristicas + ' Quadra Esportiva, ' }
    if (place?.qdtenis) { caracteristicas = caracteristicas + ' Quadra de Tênis, ' }
    if (place?.qdvolei) { caracteristicas = caracteristicas + ' Quadra de Vôlei, ' }
    if (place?.quiosquechur) { caracteristicas = caracteristicas + ' Quiosque com churrasqueira, ' }
    if (place?.rooftop) { caracteristicas = caracteristicas + ' Rooftop, ' }
    if (place?.sacada) { caracteristicas = caracteristicas + ' Sacada, ' }
    if (place?.salafesta) { caracteristicas = caracteristicas + ' Salão de festas, ' }
    if (place?.salajogos) { caracteristicas = caracteristicas + ' Sala de jogos, ' }
    if (place?.salamult) { caracteristicas = caracteristicas + ' Sala multi-uso, ' }
    if (place?.sauna) { caracteristicas = caracteristicas + ' Sauna, ' }
    if (place?.vagafixa) { caracteristicas = caracteristicas + ' Vaga fixa, ' }
    if (place?.vagarotativa) { caracteristicas = caracteristicas + ' Vaga rotativa, ' }
    if (place?.gardem) { caracteristicas = caracteristicas + ' Térreo com jardim, ' }
    if (place?.divulgacao) { caracteristicas = caracteristicas + ' divulgação link:' + place.divulgacao + ' ' }
  }
  imovel = identificacao + ', ' + investimento + ', ' + caracteristicas

  if (tpMessage === 'pessoal') {
    // prompt = `Elabore uma mensagem respondendo a um lead prévio, para apresentar ofertas de imóveis novos, simples, que se encaixam em diferentes perfis, para o Sr.${cliente}, assinado por ${corretor}, corretor de imóveis, da imobiliária  ${imobiliaria}. Pergunte se pode encaminhar algumas propostas?`
    //prompt = `Elabore uma mensagem curta, não formal, em resposta, para apresentar alguns empreendimentos, simples, assinado por ${corretor}, corretor de imóveis, da imobiliária  ${imobiliaria}. Pergunte se pode encaminhar algumas propostas?`
    prompt =`Elabore uma mensagem informal curta em resposta, agradecendo o contato e a oportunidade para apresentar alguns empreendimentos, sendo que para agilizar o processo se faz necessário o encaminhamento dos seguintes documentos para análise de financiamento: CPF, 3 últimos contra-cheques ou o extrato bancário dos últimos 6 meses, e comprovante de endereço.`
  }

  if (tpMessage === 'apresentacao') {
    prompt = `Elabore uma mensagem não formal, para whatsapp para apresentar o ${imovel} para o Sr.${cliente}, assinado por ${corretor}, corretor de imóveis, da imobiliária  ${imobiliaria}. `
  }
  if (tpMessage === 'financeiro') {
    // prompt = `Elabore em português, uma mensagem para whatsapp para continuar as negociações de venda, lembrando do ${imovel}, para o Sr.${cliente}, do ${corretor} corretor de imóveis, da imobiliária  ${imobiliaria}.`
    prompt = `Elabore uma mensagem, não formal, para whatsapp para dar continuidade as negociações de venda do ${imovel}, com o sr.${cliente}, pelo ${corretor}, corretor de imóveis, da imobiliária  ${imobiliaria}.`
  }
  if (tpMessage === 'completo') {
    prompt = `Elabore uma mensagem para whatsapp do ${corretor}, corretor de imóveis, da imobiliária  ${imobiliaria}, para dar continuidade na negociação com o Sr.${cliente}, com as informações do ${imovel}, de forma bem explicativa. `
  }


  return prompt

}