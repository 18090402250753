import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useRef, useState } from 'react';
import { acceptInvite, excludeInvite, getAllInviteByIdUser } from '../../controllers/inviteController';
import { red, yellow } from '@mui/material/colors';
import { canMoreInvites } from '../../constants/perfil';
import { plans } from '../../constants/plans';


export function ConfirmationConvite(props) {
  const { user, onClose, open, ...other } = props;
  const [value, setValue] = useState();
  const [list, setList] = useState(null);
  const [canTeams, setCanTeams] = useState(false)
  const radioGroupRef = useRef(null);
  const [msg, setMsg] = useState('')

  useEffect(() => {
    getAllInvites(user.id)
    setCanTeams(canMoreInvites(user))
  }, [])

  async function getAllInvites(_idUser) {
    const result = await getAllInviteByIdUser(_idUser)
    if (result.sucess) {
      if (result.data.length > 0) setList(result.data)
      else setList(null)
    }
  }

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleAceitar = async () => {
    let result = await acceptInvite(user, value)
    if (result.sucess) {
      setMsg('Convite aceito com sucesso. Para entrar na nova equipe faça login novamente...')
      await getAllInvites(user.id)
      setValue(null)
    }
  };

  const handleExcluir = async () => {
    let result = await excludeInvite(user.id, value)
    if (result.sucess) {
      await getAllInvites(user.id)
      setValue(null)
    }
  }

  const handleChange = (event) => {
    list.map((op) => {
      if (op.id === event.target.value) setValue(op)
    })
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Convites recebidos:</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="convites"
          name="convites"
          onChange={handleChange}
        >
          {list && list.map((option) => (
            <FormControlLabel
              value={option.id}
              key={option.id}
              control={<Radio />}
              label={option.equipe + ' (' + option.email + ')'}
            />
          ))}
        </RadioGroup>
        {msg && <div style={{ textAlign: 'center', color: red[300], width: '100%' }}> <span>{msg}</span></div>}
        {!canTeams && user.plano &&
          <div style={{ textAlign: 'center', color: red[300], width: '100%' }}>
            <span>Limite de participação em outras equipes alcançado.</span>
          </div>}
        {/* {user.plano === plans.planFree.title.toLocaleUpperCase() &&
          <div style={{ textAlign: 'center', color: red[500], width: '100%' }}>
            <span>Para participar de outra equipe é necessário uma assinatura ativa.</span>
          </div>} */}

      </DialogContent>

      <DialogActions>
        <Button onClick={handleExcluir} disabled={value ? false : true}>Não aceitar</Button>
        <Button onClick={handleAceitar} disabled={value && canTeams ? false : true}>Aceitar</Button>
        <Button onClick={handleCancel} autoFocus>Sair</Button>
      </DialogActions>
    </Dialog>
  );
}