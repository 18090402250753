import { collection, query, getDocs, doc, getDoc,  updateDoc, where, serverTimestamp, writeBatch } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { result } from '../utils/result';
import { deleteUser } from 'firebase/auth';

export async function deleteConta(_user) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em deleteConta', _user)

  try {
    result.sucess = true
    result.msg = 'Conta apagada com sucesso.'
    result.data = _user

    const batch = writeBatch(db)

    //Desativar e Marcar Conta para processo de exclução
    batch.update(doc(db, 'contas/' + _user.id), { 'wasdeleted': true, 'deletedat': serverTimestamp(), 'ativo': false })
    // Apagar contas/operadores
    // Apagar contas/places
    // Apagar contas/contas

    // Apagar participação em contas de terceiros-> contas/terceiro/operadores
    // Desenvolver processo
    _user.contas.map((cta) => {
      if (cta !== _user.id) {
        const docRef = doc(db,'contas/' + cta + '/operadores/', _user.id)
        batch.delete(docRef)
      }
    })


    // Desativar e Marcar Usuario para processo de exclusão
    // Apagar de Usuarios
    // Apagar usuarios/convites
    batch.update(doc(db, 'usuarios/' + _user.id), { 'wasdeleted': true, 'deletedat': serverTimestamp(), 'ativo': false })

    // Apagar token
    localStorage.removeItem('token')
    // Excluir Usuario do Google Authentication
    deleteUser(auth.currentUser)
    await batch.commit();
  }
  catch (e) {

    result.sucess = false
    result.msg = 'Falha no processo de exclusão da conta.'
    result.data = e
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deleteConta', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de deleteConta', result)
    return result
  }
}

export async function getContaMessage(_conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getContaMessage', _conta)
  let list = [];
  try {
    const q = query(collection(db, "contas/" + _conta + '/mensagens'));
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      list.push(doc.data())
    });
    result.sucess = true
    result.data = list
    result.msg = 'Sucesso na operação...'
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getContaMessage', e)
    result.sucess = false
    result.data = e
    result.msg = 'Falha na operação...'
    return result
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getContaMessage', result)
    return result
  }
}


export async function getContaController(conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getContaController', conta)
  try {
    const docRef = query(doc(db, 'contas/' + conta))
    const queryData = (await getDoc(docRef)).data();
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getContaController', queryData)
    return queryData
  } catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getContaController', e)
    return null
  }
}

export async function getContaConvite(_convite) {
  let contas = [];
  try {
    const q = query(collection(db, "contas"),
      where("convite", "==", _convite),
      where("conviteval", ">=", new Date())
    );
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      contas.push(doc.id)
    });
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(contas, 'data:', new Date(), q)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(e)
  }
  finally {
    return contas
  }
}

export async function setCodigoConvite(codigo, validade, conta) {
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou setCodigoConvite', codigo, validade, conta)
    return await updateDoc(doc(db, 'contas', conta), { convite: codigo, conviteval: new Date(validade) })
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha setCodigoConvite', e)
  }
}

