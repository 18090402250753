import { collection, deleteDoc, query, getDocs, doc, addDoc, getDoc, setDoc, updateDoc, runTransaction, where, serverTimestamp, Query, Timestamp, FieldValue, arrayUnion, arrayRemove, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';
import { result } from '../utils/result';
import { getMesAno } from '../utils/getMesAno';




export async function getMessagesAI(idconta, idplace) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getMessageAI')
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    let list = []
    const q = query(collection(db, `/contas/${idconta}/places/${idplace}/messageAI/`), where('ativo', '==', true));
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      let msg = doc.data()
      msg.id = doc.id
      list.push(msg)
    });
    result.data = list
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getMessageAI', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getMessageAI', result)
    return result
  }
}

export async function saveMessageAI(idconta, idplace, tpmessage, message, idoperador, tokens = 0) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em saveMessageAI')
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const msg = {
      idoperador: idoperador,
      tokens: tokens,
      message: message,
      ativo: true,
      createdat: serverTimestamp()
    }

    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('saveMessageAI - setDoc')
    const docRef = doc(db, `/contas/${idconta}/places/${idplace}/messageAI/${tpmessage}`)
    await setDoc(docRef, msg)
    result.data = msg
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em saveMessageAI', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de saveMessageAI', result)
    return result
  }

}

export async function deleteMessageAI(idconta, idplace, tpmessage) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em deleteMessageAI', tpmessage)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const docRef = doc(db, `/contas/${idconta}/places/${idplace}/messageAI/${tpmessage}`)
    await deleteDoc(docRef)
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deleteMessageAI', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de deleteMessageAI', result)
    return result
  }
}

export async function setBloquearCredito(iduser, vlrMovimento) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setConsumirCredito', iduser, vlrMovimento)
  const contaRef = doc(db, `usuarios/${iduser}`)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null

    const newCredito = await runTransaction(db, async (transaction) => {

      const sfDoc = await transaction.get(contaRef);

      if (!sfDoc.exists()) { throw 'Document does not exist!' }

      const newCdto = sfDoc.data().credito - vlrMovimento

      if (newCdto >= 0) {

        // Atualiza o valor do Credito do Operador

        transaction.update(doc(db, 'usuarios', iduser), { credito: newCdto })

        // Registra operação no em openai/mesano/
        const docRegOp = collection(db, `openai/tokens/${getMesAno()}`)
        await addDoc(docRegOp, { idoperador: iduser, mov: 'D', vlr: vlrMovimento, historico: 'consumo AI', data: serverTimestamp() })

        return newCdto
      } else {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falta de Crédito', newCdto)
        result.data = newCdto
        return Promise.reject('Atenção! Créditos suficientes para a operação!')
      }
    })
    result.data = newCredito
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Atenção! Créditos suficientes para bloqueio!'
    result.data = e
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em setConsumirCredito: ', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setConsumirCredito: ', result)
    return result
  }

}

export async function setAjustarCredito(iduser, vlrMovimento) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setAjustaCredito', iduser, vlrMovimento)
  const contaRef = doc(db, `usuarios/${iduser}`)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const newCredito = await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(contaRef);
      if (!sfDoc.exists()) {
        throw 'Document does not exist!'
      }
      const newCrdto = sfDoc.data().credito + vlrMovimento

      // Atualiza o valor do Credito do Operador
      transaction.update(doc(db, 'usuarios', iduser), { credito: newCrdto })

      // Registra operação em openai/mesano/
      let mov = 'C'
      if (vlrMovimento < 0) mov = 'D'
      const docRegOp = collection(db, `openai/tokens/${getMesAno()}`)
      await addDoc(docRegOp, { idoperador: iduser, mov: mov, vlr: vlrMovimento, historico: 'Ajuste AI', data: serverTimestamp() })


      return newCrdto
    })
    result.data = newCredito
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Crédito ajustado:', newCredito)
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em setAjustaCredito', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setAjustaCredito', result)
    return result
  }

}

