import { addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, runTransaction, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { result } from '../utils/result';
import { getUsuarioByEmail } from './userController';
import { saveOperador } from './equipeController';


export async function excludeInvite(_idUser, _invite) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no excludeInvite', _invite)
  try {
    result.sucess = true
    result.msg = 'Sucesso na operação...'
    await runTransaction(db, async (transaction) => {
      // Excluir Convite do registro do usuario
      const docRefExc = doc(db, 'usuarios/' + _idUser + '/convites', _invite.id)
      await deleteDoc(docRefExc)
    })
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro no excludeInvite', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu do excludeInvite', result)
    return result
  }

}


export async function acceptInvite(user, _invite) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no acceptInvite', _invite)
  try {
    result.sucess = true
    result.msg = 'Sucesso na operação...'
    await runTransaction(db, async (transaction) => {
      // Incluir conta no registro do usuario
      const docRef = doc(db, 'usuarios', user.id)
      await updateDoc(docRef, { conta: arrayUnion(_invite.conta) })
      // Excluir Convite do registro do usuario
      await excludeInvite(user.id, _invite)
      // Incluir Operador na Conta de Terceiros
      await saveOperador(user, _invite.conta)
    })
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro no acceptInvite', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu do acceptInvite', result)
    return result
  }

}

export async function getAllInviteByIdUser(_idUser) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no getAllInviteByIdUser', _idUser)
  let list = []
  try {
    const querySnapshot = await getDocs(collection(db, "usuarios/" + _idUser + "/convites"));
    querySnapshot.forEach((doc) => {
      let _inv = doc.data()
      _inv.id = doc.id
      list.push(_inv);
    });
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro no getAllInviteByIdUser', e)
    return result
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu do getAllInviteByIdUser', list)
    result.data = list
    result.sucess = true
    result.msg = 'Consulta realizada com sucesso...convites'
    return result
  }
}

export async function enviarConviteEmail(_email, _equipe, _conta) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em enviarConviteEmail:', _email, _equipe, _conta)
  // Carrega operador convidado
  let operadores = await getUsuarioByEmail(_email)
  // Se operador localizado no sistema, monta convite
  if (operadores.length === 1) {
    const convite = {
      convite: _equipe.convite,
      equipe: _equipe.equipe,
      email: _equipe.email,
      conviteval: _equipe.conviteval,
      conta: _conta
    }
    // Carrega todos os convites do operador convidado, com igual convite.email
    const convites = await getAllConvite(operadores[0], convite)
    try {
      // Se não possui convite, cadastra convite para o operador
      if (convites.length === 0) {
        const docRef = collection(db, 'usuarios/' + operadores[0].id + "/convites")
        await addDoc(docRef, convite)
        result.sucess = true
        result.msg = 'Convite enviado com sucesso...'
      }
      // Se não retorna convite já enviado
      else {
        result.sucess = true
        result.msg = 'Convite já enviado...'
      }
    }
    catch (e) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em getAllConvite', e)
      return result
    }
    finally {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de enviarConviteEmail:', result)
      return result
    }
  }
  result.sucess = false
  result.msg = 'Email convidado não cadastrado no sistema...'
  return result
}

async function getAllConvite(_operador, _convite) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getAllConvite:', _operador, _convite)
  let convites = [];
  try {
    const q = query(collection(db, "usuarios/" + _operador.id + "/convites"),
      where('email', "==", _convite.email)
    );
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      let con = doc.data()
      con.id = doc.id
      convites.push(con)
    });
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em getAllConvite', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getAllConvite:', convites)
    return convites
  }
}
