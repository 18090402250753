

// GPT 3.5 Turbo 
// 04k -> $0,0015 / 1k Tokens => 1k = R$ 0,0073 
// => R$ 10,00 -> 1.369.863,01 tokens 
// => R$ 5,00 -> 684.930,0 tokens
// => R$ 1,00 = 136.986,3 tokens 

import { openai } from './openai'

// 16k -> $0,0030 / 1k Tokens => 1k = R$ 0,015  
// => R$ 10,00 -> 666.666,66 tokens 
// => R$ 5,00 -> 333.333,3 tokens
// => R$ 1,00 = 66.666,66 tokens 

export async function generateAIText(texto, max_tokens) {

  const prompt = texto

  const temperature = 0.7

  if (max_tokens > 750) { max_tokens = 750 }

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Iniciou AI')
  const response = await openai.chat.completions.create({
    model: 'gpt-3.5-turbo-16k',
    temperature,
    messages: [
      { role: 'user', content: prompt }
    ],
    max_tokens: max_tokens
  })
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Encerrou AI', response)
  return response
}

