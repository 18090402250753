import { Box, FormControl, IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { loadURlImgEmpreendimentos } from '../../../api/storage'
import { PhotoCamera } from '@mui/icons-material'
import { isUrl } from '../../../utils/validURL'

// const LoadImg = ({ src, id }) => {
//   let u = null
//   if (typeof src === 'object') {
//     if (src) u = URL.createObjectURL(src)
//   }
//   else {
//     if (!src.includes('http')) {
//       async function load() {
//         const _url = await loadURlImgEmpreendimentos(id)
//         return _url
//       }
//       load().then((url) => {
//         u = url
//         const img = document.getElementById('imgEmp')
//         img.setAttribute('src', url)
//       })
//     }
//     else {
//       u = src
//     }
//   }
//   return (
//     <>
//       <input
//         type="file"
//         id='file'
//         accept="image/*"
//         name="file"
//       // onChange={setImgEmpreendimento}
//       />
//       <img
//         id='imgEmp'
//         alt="not found"
//         width={'100%'}
//         src={u ? u : null}
//       />
//       <br />

//       <button
//       // onClick={delImgEmpreeendimento}
//       >Remove</button>
//     </>
//   )
// }


export const Nuvem = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace, setFileImg, fileImg }) => {

  let _place = placeEdit
  const [urlimagem, setUrlImagem] = useState(_place.hasOwnProperty('urlimagem') ? _place.urlimagem : newPlace.urlimagem)
  const [drive, setDrive] = useState(_place.hasOwnProperty('drive') ? _place.drive : newPlace.drive)
  const [tbvendas, setTbVendas] = useState(_place.hasOwnProperty('tbvendas') ? _place.tbvendas : newPlace.tbvendas)
  const [plantas, setPlantas] = useState(_place.hasOwnProperty('plantas') ? _place.plantas : newPlace.plantas)
  const [memorial, setMemorial] = useState(_place.hasOwnProperty('memorial') ? _place.memorial : newPlace.memorial)
  const [implantacao, setImplantacao] = useState(_place.hasOwnProperty('implantacao') ? _place.implantacao : newPlace.implantacao)
  const [divulgacao, setDivulgacao] = useState(_place.hasOwnProperty('divulgacao') ? _place.divulgacao : newPlace.divulgacao)
  const [selectedImage, setSelectedImage] = useState(_place.hasOwnProperty('file') ? _place.file : null)

  function handleInput(event) {

    if (event.target.id === 'urlimagem') {
      console.log('Edit url')
      setUrlImagem(event.target.value)
    }
    if (event.target.id === 'drive') { setDrive(event.target.value) }
    if (event.target.id === 'tbvendas') { setTbVendas(event.target.value) }
    if (event.target.id === 'plantas') { setPlantas(event.target.value) }
    if (event.target.id === 'memorial') { setMemorial(event.target.value) }
    if (event.target.id === 'implantacao') { setImplantacao(event.target.value) }
    if (event.target.id === 'divulgacao') { setDivulgacao(event.target.value) }
  }

  function setPlace() {
    _place.urlimagem = urlimagem ? urlimagem : ''
    _place.drive = drive ? drive : ''
    _place.tbvendas = tbvendas ? tbvendas : ''
    _place.plantas = plantas ? plantas : ''
    _place.memorial = memorial ? memorial : ''
    _place.implantacao = implantacao ? implantacao : ''
    _place.divulgacao = divulgacao ? divulgacao : ''
  }

  const style = {
    my: '5px',
    mr: '10px'
  }


  async function delImgEmpreeendimento() {
    const urlImg = document.getElementById('urlimagem')
    urlImg.value = ''
    const img = document.getElementById('imgEmp')
    if (img) img.setAttribute('src', 'assets/images/gimov.png')
    setUrlImagem('')
  }



  async function loadImgEmpreendimento() {
    let _url = ''
    if (!isUrl(urlimagem)) {
      if (selectedImage && urlimagem) _url = URL.createObjectURL(selectedImage)
    }
    else {
      if (urlimagem.includes('firebasestorage.googleapis.com')) _url = await loadURlImgEmpreendimentos(newPlace.id)
      else _url = urlimagem
    }
    setSelectedImage(_url)
    if (_url) {
      const img = document.getElementById('imgEmp')
      if (img) img.setAttribute('src', _url)
    }
  }

  function handleLoadImage(event) {
    var file = event.target.files[0];
    const url = URL.createObjectURL(file)
    setSelectedImage(file)
    setUrlImagem(file.name)
    const img = document.getElementById('imgEmp')
    img.setAttribute('src', url)
    placeEdit.file = file
  }

  useEffect(() => {
    loadImgEmpreendimento()
  }, [urlimagem])

  return (

    <FormControl sx={{ width: '100%' }} onBlur={setPlace} >
      <Grid2 container spacing={2}>
        <Grid2 container xs={12} >
          <Grid2 container xs={8} >
            <TextField sx={style}
              // ref={refImg}
              size='small'
              autoFocus
              fullWidth
              id="urlimagem"
              name='urlimagem'
              label="URL da Foto do empreendimento"
              value={urlimagem}
              onChange={handleInput}
              onBlur={(event) => { setSelectedImage(event.target.value) }}
              helperText="Link da foto do empreendimento do site da construtora"
            />
            <TextField sx={style}
              size='small'
              fullWidth
              id="drive"
              name='drive'
              label="Drive"
              value={drive}
              onChange={handleInput}
              helperText="Link do Drive da construtora"
            />
            <TextField sx={style}
              size='small'
              fullWidth
              id="tbvendas"
              name='tbvendas'
              label="Tabela de Vendas"
              value={tbvendas}
              onChange={handleInput}
              helperText="Pasta TABELA DE VENDAS do drive da construtora"
            />
          </Grid2>
          <Grid2 container xs={4} sx={{
            borderStyle: 'dotted',
            borderRadius: '10px',
            padding: '5px',
          }}>
            <Box height={'100%'} width={'100%'} display={'flex'} flexDirection={'column'}  >
              <Box>
                <img
                  id='imgEmp'
                  alt="..."
                  width={'100%'}
                  style={{ display: selectedImage ? 'block' : 'none' }}
                />
              </Box>
              <Box display={'flex'} justifyContent={'flex-end'}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={delImgEmpreeendimento}
                >-<PhotoCamera />
                </IconButton>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input hidden accept="image/*" type="file" onChange={handleLoadImage} />
                  +
                  <PhotoCamera />
                </IconButton>
              </Box>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 xs={12}>
          <TextField sx={style}
            size='small'
            fullWidth
            id="plantas"
            name='plantas'
            label="Plantas"
            value={plantas}
            onChange={handleInput}
            helperText="Pasta PLANTAS do drive da construtora"
          />
          <TextField sx={style}
            size='small'
            fullWidth
            id="memorial"
            name='memorial'
            label="Memorial Descritivo"
            value={memorial}
            onChange={handleInput}
            helperText="Pasta MEMORIAL DESCRITIVO do drive da construtora"
          />
          <TextField sx={style}
            size='small'
            fullWidth
            id="implantacao"
            name='implantacao'
            label="Implantação"
            value={implantacao}
            onChange={handleInput}
            helperText="Pasta IMPLANTAÇÃO do drive da construtora"
          />
          <TextField sx={style}
            size='small'
            fullWidth
            id="divulgacao"
            name='divulgacao'
            label="Divulgação"
            value={divulgacao}
            onChange={handleInput}
            helperText="Pasta DIVULGAÇÃO do drive da construtora"
          />
        </Grid2>
      </Grid2>
    </FormControl>
  )
}