import { useEffect, useState } from 'react';
import {
  Grid, Button, Card, CardContent,
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle,
  FormControl, TextField, Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';

import gerarCodigo from '../../../utils/gerarCodigo';
import { getContaController, setCodigoConvite } from '../../../controllers/contaController';
import { enviarConviteEmail } from '../../../controllers/inviteController';
import { green, red } from '@mui/material/colors';
import { canUserSendInvite } from '../../../constants/perfil';
import { getQtdOperadores } from '../../../controllers/equipeController';


export function CadConvite({ user, listUsuarios }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Integrantes', listUsuarios, listUsuarios.length)

  const conta = user.conta
  const [equipe, setEquipe] = useState([])
  const [hasConviteValido, setHasConviteValido] = useState(false)
  const [openDesativarConvite, setOpenDesativarConvite] = useState(false)
  const [isLoadingDesativar, setIsLoadingDesativar] = useState(false)
  const [emailConvidado, setEmailConvidado] = useState('')
  const [msg, setMsg] = useState(null)
  const [openConfirmarConvite, setOpenConfirmarConvite] = useState(false)
  const [canSendInvite, setCanSendInvite] = useState(false)

  async function getEquipe(conta) {
    const equipe = await getContaController(conta)
    setEquipe(equipe)
    if (equipe.conviteval !== null) {
      if (new Date(equipe.conviteval.toDate()) > new Date()) { setHasConviteValido(true) }
      else setHasConviteValido(false)
    }
  }

  const [isLoadingCon, setIsLoadingCon] = useState(false)
  async function handleGerarConvite() {
    setIsLoadingCon(true)
    const codigo = gerarCodigo()
    await setCodigoConvite(codigo.codigo, codigo.validade, conta).then(() => {
      getEquipe(conta)
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu handleGerarConvite', codigo, equipe)
    setIsLoadingCon(false)
  }

  async function desativarConvite() {
    setIsLoadingDesativar(true)
    await setCodigoConvite(null, null, conta).then(() => {
      getEquipe(conta)
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu desativarConvite', conta, equipe)
    setOpenDesativarConvite(false)
    setIsLoadingDesativar(false)
  }

  function handleEmail(event) {
    setEmailConvidado(event.target.value)
    setMsg(null)
  }

  function handleEnviarConvite() {
    if (emailConvidado !== undefined && emailConvidado.length > 0) setOpenConfirmarConvite(true)
  }

  async function sendInviteByEmail() {
    setIsLoadingCon(true)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em sendInviteByEmail:', emailConvidado)
    let i = 0;
    listUsuarios.forEach((operador) => {
      if (operador.email === emailConvidado) {
        i++
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Integrante:', i, operador)
      }
    })
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Tem integrante:', i)
    if (i === 0 && emailConvidado !== '' && emailConvidado !== user.email && emailConvidado !== equipe.email) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email operador:', user.email)
      const result = await enviarConviteEmail(emailConvidado, equipe, conta)
      setMsg(result.msg)
    }
    else {
      setMsg('Já é integrante da equipe ou email é invalido...')
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email do operador inválido', emailConvidado)
    }
    setOpenConfirmarConvite(false)
    setIsLoadingCon(false)
  }


  async function getCanSendInvite(_conta) {
    const rst = await getQtdOperadores(_conta)
    if (rst.sucess) {
      if (!canUserSendInvite(user, rst.data)) {
        setMsg('Limite de operadores do plano alcançado (' + rst.data + ').')
        setCanSendInvite(false)
        await desativarConvite()
      }
      else {
        setMsg('')
        setCanSendInvite(true)
      }
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(canUserSendInvite(user, rst.data))
    }

  }

  useEffect(() => {
    getEquipe(conta)
    getCanSendInvite(conta)
  }, [])

  return (
    <Grid container
      // height={'80vh'} 
      width={'700px'}
    >
      <LoadingButton
        fullWidth
        id='btAtivarConvite'
        size="small"
        color="primary"
        onClick={handleGerarConvite}
        loading={isLoadingCon}
        loadingPosition="start"
        variant="contained"
        disabled={!canSendInvite ? true : hasConviteValido ? true : false}
        startIcon={<EmailTwoToneIcon />}
        sx={{ mx: '10px', backgroundColor: green[500], color: red[800] }}
      > Ativar código de convite
      </LoadingButton>
      <Card
        sx={{
          // width: '100%', 
          m: 1
        }} elevation={5}>
        <CardContent>
          <Grid container><Typography variant='h6'>Convite</Typography>
            <Grid container
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ p: '10px' }}
            >
              <Grid container sx={{
                width: '300px',
                p: '10px',
                border: '1px dashed grey',
                minWidth: '300px'
              }}>
                <Grid container >
                  <Typography variant='h6' sx={{ mr: '20px' }}>Código:</Typography>
                  <Typography variant='h6'>
                    {equipe && equipe.convite}
                  </Typography>
                </Grid>
                <Grid container >
                  <Typography variant='h6' sx={{ mr: '20px' }}>Validade:</Typography>
                  <Typography variant='h6'>
                    {equipe.convite ? new Date(equipe.conviteval.toDate()).toLocaleString() : ''}
                  </Typography>
                </Grid>
                <Grid container>
                  <LoadingButton
                    id='btDesativarConvite'
                    size="small"
                    color="primary"
                    onClick={() => { setOpenDesativarConvite(!openDesativarConvite) }}
                    loading={isLoadingDesativar}
                    loadingPosition="start"
                    variant="contained"
                    disabled={hasConviteValido ? false : true}
                    startIcon={<EmailTwoToneIcon />}
                    sx={{ maxHeight: '30px', width: '100%', mt: 1, backgroundColor: green[500], color: red[800] }}
                  > Desativar código do convite
                  </LoadingButton>
                </Grid>
              </Grid>
              <Grid container sx={{
                width: '300px',
                p: '10px',
                border: '1px dashed grey',
                minWidth: '300px'
              }}>
                <Typography variant='h6'>Convidar por email:</Typography>
                <Grid
                >
                  
                  <FormControl
                    sx={{
                      width: '100%',
                      my: 1
                    }}>
                    <TextField
                      size='small'
                      id="emailconv"
                      name='emailconv'
                      label='Email'
                      type='email'
                      fullWidth
                      onChange={handleEmail}
                      disabled={!canSendInvite ? true : hasConviteValido ? false : true}
                      // disabled={!canSendInvite ? true : hasConviteValido ? true : false}
                    />
                  </FormControl>
                  <LoadingButton
                    id='btEnviarConvite'
                    size="small"
                    color="primary"
                    onClick={handleEnviarConvite}
                    loading={isLoadingCon}
                    loadingPosition="start"
                    variant="contained"
                    startIcon={<EmailTwoToneIcon />}
                    sx={{ maxHeight: '30px', width: '100%', mt: 1 }}
                    disabled={!canSendInvite ? true : hasConviteValido ? false : true}
                  > Enviar Convite
                  </LoadingButton>
                  <Typography variant='subtitle2' color={'error'}>
                    {msg}
                  </Typography>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {/* Dialog Desativar Código do Convite */}
      <Dialog
        open={openDesativarConvite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmar desativação:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              Desativar código de convite {equipe.convite}?
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={desativarConvite}>Sim</Button>
          <Button onClick={() => setOpenDesativarConvite(false)}>Não</Button>
        </DialogActions>
      </Dialog>
      {/* Dialog Confirmar envio de convite */}
      <Dialog
        open={openConfirmarConvite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmar envio de convite para:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              {emailConvidado}
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={sendInviteByEmail}>Sim</Button>
          <Button onClick={() => setOpenConfirmarConvite(false)}>Não</Button>
        </DialogActions>
      </Dialog>


    </Grid>
  )
}