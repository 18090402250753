import { useState } from 'react'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask';
import { Meses } from '../../../utils/dados/DadosMeses';
import { Anos } from '../../../utils/dados/DadosAnos';




export const PolVendas = ({
  setPlaceEdit,
  placeEdit,
  setNewPlace,
  newPlace }) => {

  let _place = placeEdit

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em PolVendas', placeEdit)

  const [renda, setRenda] = useState(_place?.renda ? _place.renda : (newPlace.renda ? newPlace.renda : ''))
  const [vlrInicial, setValorInicial] = useState(_place?.vlrinicial ? _place.vlrinicial : (newPlace.vlrinicial ? newPlace.vlrinicial : ''))
  const [vlrFinal, setVlrFinal] = useState(_place?.vlrfinal ? _place.vlrfinal : (newPlace.vlrfinal ? newPlace.vlrfinal : ''))
  const [promocao, setPromocao] = useState(_place?.promocao ? _place.promocao : (newPlace.promocao ? newPlace.promocao : ''))
  const [refVlrMes, setRefVlrMes] = useState(_place?.refvlrmes ? _place.refvlrmes : (newPlace.refvlrmes ? newPlace.refvlrmes : ''))
  const [refVlrAno, setRefVlrAno] = useState(_place?.refvlrano ? _place.refvlrano : (newPlace.refvlrano ? newPlace.refvlrano : ''))

  const handleInput = (event) => {
    if (event.target.id === 'renda') { setRenda(event.target.value) }
    if (event.target.id === 'vlrinicial') { setValorInicial(event.target.value) }
    if (event.target.id === 'vlrfinal') { setVlrFinal(event.target.value) }
    if (event.target.id === 'promocao') { setPromocao(event.target.value) }
    if (event.target.name === 'refvlrmes') { setRefVlrMes(event.target.value) }
    if (event.target.name === 'refvlrano') { setRefVlrAno(event.target.value) }
  }

  function setPlace() {
    _place.renda = renda ? renda : ''
    _place.vlrinicial = vlrInicial ? vlrInicial : ''
    _place.vlrfinal = vlrFinal ? vlrFinal : ''
    _place.promocao = promocao ? promocao : ''
    _place.refvlrmes = refVlrMes ? refVlrMes : ''
    _place.refvlrano = refVlrAno ? refVlrAno : ''
  }


  const style = {
    my: 1, mx: 1
  }

  const styleRow = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  }
  const styleCol = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

  }

  return (
    <FormControl sx={{ width: '100%' }} onBlur={setPlace}>
      <Box sx={styleCol}>
        <TextFieldMask sx={{ maxWidth: 150, m: 1 }}
          // size='small'
          autoFocus
          id="renda"
          name='renda'
          label="Renda"
          value={renda}
          onChange={handleInput}
          onBlur={handleInput}
          mask={'currency'} moeda={'R$'}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        />

        <Box sx={styleRow}>
          <TextFieldMask sx={{ mt: 1, ml: 1 }}
            id="vlrinicial"
            name='vlrinicial'
            label="Imóvel de menor valor"
            value={vlrInicial}
            onChange={handleInput}
            onBlur={handleInput}
            mask={'currency'} moeda={'R$'}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          />
          <TextFieldMask sx={{ mt: 1, mx: 1 }}
            id="vlrfinal"
            name='vlrfinal'
            label="Imóvel de maior valor"
            value={vlrFinal}
            onChange={handleInput}
            onBlur={handleInput}
            mask={'currency'} moeda={'R$'}
          // InputLabelProps={{
          //   shrink: true,
          // }}
          />
          <Grid>
            <FormControl sx={{ mt: 1, minWidth: 85 }}>
              <InputLabel id="lbRefMes">Mês Ref</InputLabel>
              <Select
                label='Mês ref'
                name='refvlrmes'
                id="refvlrmes"
                labelId="lbRefMes"
                value={refVlrMes||''}
                onChange={handleInput}
              >
                <MenuItem value=''><em>None</em></MenuItem>
                {Meses.map((m) =>
                  <MenuItem key={m.mes} value={m.mes}> {m.sigla}</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 85 }}>
              <InputLabel id="lbRefAno">Ano Ref</InputLabel>
              <Select
                label='Ano ref'
                name='refvlrano'
                id="refvlrano"
                labelId="lbRefAno"
                value={refVlrAno||''}
                onChange={handleInput}
              >
                <MenuItem value=''><em>None</em></MenuItem>
                {Anos.map((a) =>
                  <MenuItem key={a.ano} value={a.ano}> {a.ano}</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Box>
      <Box>
        <TextField sx={style}
          size='small'
          fullWidth
          id="promocao"
          name='promocao'
          label="Bonus e Promoções"
          multiline
          rows={15}
          value={promocao}
          onChange={handleInput}
          helperText="Bonificações e promoções para o corretor e ou cliente "
          inputProps={{ maxLength: 1000 }}
        />
      </Box>
    </FormControl>
  )
}