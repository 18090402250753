import { colors } from '@mui/material'

export const paper = () => {
  return {
    padding: '10px', 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'center', 
  }
}
export const box = () => {
  return {
    height: '85vh', 
    bgcolor: colors.grey[500],
    display:'flex'
  }
}