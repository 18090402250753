import { Box, Typography } from '@mui/material'

export default function PoliticaPrivacidade() {

  return (
    <Box>
      <Typography component="h1" variant="h6">
        POLÍTICA DE PRIVACIDADE
      </Typography>
      <br />
      A presente versão desta Política foi atualizada pela última vez em: 23/06/2023.
      <Typography variant='subtitle1'>
        1. Introdução
      </Typography>
      Obrigado por escolher o GIMOV!

      No GIMOV, desejamos proporcionar a melhor experiência possível para garantir que você desfrute ao máximo do nosso serviço. A sua privacidade e a segurança dos seus dados pessoais são e serão sempre de extrema importância para nós. Assim, gostaríamos de demonstrar de forma transparente como e porque coletamos, armazenamos, compartilhamos e utilizamos os seus dados pessoais – bem como definir os controles e opções que você pode utilizar para compartilhar os seus dados pessoais.

      Este é o nosso objetivo, e na Política de Privacidade (“Política”) anexa, serão fornecidos todos os esclarecimentos e detalhes necessários.

      <Typography variant='subtitle1'>
        2. Sobre a Política
      </Typography>
      Esta Política define os detalhes essenciais relativos à sua lista de dados pessoais com a GATE 51 DESENVOLVIMENTO DE SISTEMAS DE INFORMAÇÃO LTDA. Nossa Política está de acordo com a Lei Geral de Proteção da Dados Pessoais do Brasil (LGPD - Lei Federal 13.709/18) e aplica-se a todos os serviços GIMOV e quaisquer serviços associados ("Serviço"). As condições que regem seu uso do Serviço encontram-se definidas no nosso documento Termos e Condições de Uso ("Termos e Condições de Uso").

      Ocasionalmente poderemos desenvolver novos serviços ou oferecer serviços adicionais. Caso a introdução de tais serviços novos ou adicionais resultar em qualquer alteração relevante na forma pela qual coletamos ou processamos seus dados pessoais, forneceremos ao Usuário informações, condições ou políticas adicionais. Ressalvada outra forma indicada ao serem introduzidos serviços novos ou adicionais, os mesmos estarão sujeitos à presente Política.

      O objetivo da presente Política é:

      Garantir que o Usuário compreenda as razões pelas quais coletamos e utilizamos os seus dados pessoais e de que forma são compartilhados;
      Explicar a forma pela qual utilizamos os dados pessoais do Usuário, com o fim de proporcionar a todos uma excelente experiência de uso dos Serviços; e,
      Explicar os direitos e opções de escolha do Usuário referentes aos seus dados pessoais que coletamos e processamos e de que forma preservamos sua privacidade.
      A presente Política de Privacidade é aplicada aos dados fornecidos pelo Usuário e coletados quando o mesmo cria uma conta, publica anúncios, troca mensagens, navega ou faz qualquer uso dos Serviços. Em qualquer caso, o GIMOV cumpre com toda legislação aplicável à proteção de dados de onde opera. Usar os Serviços implica na compreensão, aceitação e vinculação do Usuário a esta Política de Privacidade e aos Termos e Condições de Uso de cada Serviço que o mesmo utilizar. Se o Usuário não concordar com quaisquer condições previstas aqui, poderá deixar de utilizar os Serviços.

      Esta Política de Privacidade não se aplica para sites de terceiros, ainda que o Usuário os acesse por links contidos nos Serviços, pois tais sites estão fora do nosso ambiente de hospedagem, e as informações neles contidas estão fora do nosso controle direto. Cabe ao Usuário verificar se está de acordo com as Políticas de Privacidade de terceiros.

      Esperamos que o exposto ajude a compreender os nossos compromissos de privacidade para com os seus dados pessoais. Para obter informações sobre como entrar em contato conosco em caso de dúvidas ou esclarecimentos, consulte a Seção 13 "Como entrar em contato conosco?" abaixo. Caso não concorde com o conteúdo da presente Política, o Usuário poderá optar por não usar os Serviços.
      <Typography variant='subtitle1'>
        3. Seus direitos e preferências: Garantimos opções de escolha e controle
      </Typography>
      A Lei Geral de Proteção de Dados ("LGPD") confere certos direitos às pessoas físicas no que diz respeito aos seus dados pessoais. Dessa forma, buscamos garantir transparência e controles de acesso a fim de permitir aos usuários se beneficiar dos direitos mencionados. Os direitos conferidos aos Usuários, sem prejuízo das limitações previstas na legislação aplicável, são os seguintes:

      Direito de confirmação e acesso: é o direito do Usuário de obter do GIMOV a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;
      Direito de retificação: é o direito do Usuário de obter do GIMOV, sem demora injustificada, a retificação dos dados pessoais inexatos, incompletos ou desatualizados que lhe digam respeito;
      Direito à informação: é o direito do Usuário de ser informado sobre o compartilhamento de seus dados com entes públicos e privados, caso sejam solicitados ou utilizados;
      Direito à informação sobre o não consentimento: é o direito do Usuário ser informado sobre a opção de não autorizar o tratamento e as consequências da não autorização;
      Direito à eliminação dos dados (direito ao esquecimento): é o direito do Usuário de ter seus dados apagados do GIMOV, exceto quando o tratamento é legal, conforme previsto em lei, mesmo sem o seu consentimento;
      Direito à limitação do tratamento dos dados: é o direito do Usuário de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando o GIMOV não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;
      Direito de oposição: é o direito do Usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de marketing (profiling) e revogar o seu consentimento, nos termos da lei;
      Direito de portabilidade dos dados: é o direito do Usuário de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao GIMOV, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro aplicativo;
      Direito de não ser submetido a decisões automatizadas: é o direito do Usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar; e,
      Direito à reclamação: é o direito do Usuário de reclamar contra o GIMOV em casos de uso não autorizado ou inadequado dos seus dados junto à autoridade nacional.
      Para saber mais sobre estes direitos, exercê-los com facilidade e registrar as suas preferências em relação à forma como o GIMOV utiliza os dados pessoais dos Usuários, fornecemos os seguintes recursos:

      Configurações de Notificações (as quais o Usuário pode acessar por meio da página da sua conta) - permite que o Usuário selecione as comunicações de marketing que recebe do GIMOV. Pode alterar as configurações para aderir ou cancelar a assinatura de diferentes tipos de e-mail e notificações. Observe que as mensagens de marketing do GIMOV enviadas por e-mail incluem um mecanismo de cancelamento de recebimento de mensagens dentro da própria mensagem (por exemplo, um link para cancelar a assinatura de e-mails que lhe enviamos). O Usuário pode utilizar a página Configurações de Notificações para selecionar todas as categorias de e-mail e comunicações de marketing; e,
      Política de cookies - fornece informações adicionais sobre como utilizar os cookies, incluindo para publicidade. O Usuário encontrará também informações sobre como gerenciar as suas preferências de cookies e desativar certos tipos de acompanhamento.
      Em caso de dúvida sobre a privacidade e os direitos do Usuário, ou como exercê-los, entre em contato com o nosso Encarregado pelo Tratamento de Dados Pessoais através do formulário "Entre em contato conosco" na Central de Privacidade. Se tiver dúvidas sobre o processamento dos seus dados pessoais, gostaríamos de esclarecê-las. No entanto, o Usuário também pode contatar e apresentar uma reclamação junto à Autoridade Nacional de Proteção de Dados (“ANPD”).
      <Typography variant='subtitle1'>
        4. Como coletamos os seus dados pessoais?
      </Typography>
      As informações que o GIMOV coleta sobre o Usuário e o modo como são coletadas podem variar de acordo com o Serviço. Estas informações podem ainda ser obtidas através de um terceiro que tenha a sua permissão para compartilhar as informações do Usuário com o GIMOV. De todas as formas, a obtenção dos dados do Usuário observará as hipóteses previstas no art. 7º da LGPD.

      Nas tabelas abaixo, estabelecemos as categorias de dados pessoais que coletamos e usamos e como os coletamos:

      A tabela abaixo descreve os dados pessoais coletados ao utilizar os Serviços:

      Categorias de dados pessoais	Descrição da categoria
      Dados Cadastrais

      Estes são os dados pessoais fornecidos pelo Usuário ou coletados pelo GIMOV para permitir que o mesmo utilize os Serviços. Os referidos dados pessoais incluem o nome completo do usuário, e-mail, telefone, estado e cidade de residência, dados do CRECI e foto pessoal.

      Alguns dos dados pessoais solicitados são necessários para criar a sua conta. O Usuário também possui a opção de nos prover dados pessoais adicionais para tornar a sua conta mais personalizada.

      Os dados pessoais exatos que iremos recolher variam se o Usuário utilizar serviços de terceiros (como o Google, Facebook e Apple) para se registrar e utilizar o Serviços. Caso o Usuário utilize um serviço de terceiros para criar uma conta, receberemos dados pessoais através deste serviço de terceiros, mas apenas mediante seu consentimento de que o referido serviço de terceiros compartilhará conosco os seus dados pessoais.

      A tabela abaixo descreve os dados pessoais coletados através da sua utilização dos Serviços:

      Categorias de dados pessoais	Descrição de categoria
      Dados de Utilização	Estes são os dados pessoais coletados sobre o Usuário quando acessa e/ou usa os Serviços, ou seja:
      Informação sobre a sua conta nos Serviço.
      Informação sobre as suas interações com os Serviços, tais como as suas pesquisas (incluindo a data e a hora de quaisquer solicitações que efetuar), histórico de navegação e interações com os Serviços.
      Conclusões extraídas sobre os seus interesses e preferências com base na sua utilização dos Serviços.
      Alguns dados pessoais técnicos, que podem incluir:
      informação de URL;
      identificadores online, incluindo dados de cookies e endereços IP;
      informação sobre os tipos de dispositivos que utiliza, como ID’s de dispositivos exclusivos, tipo de conexão de rede (por exemplo, wifi, 3G, LTE, Bluetooth), prestador, desempenho da rede e do dispositivo, tipo de navegador, idioma, informações para gerenciamento de direitos digitais, sistema operacional e versão da aplicação GIMOV;
      a sua localização pode ser detectada ou inferida a partir de certos dados técnicos (por exemplo, o serviço de localização do seu dispositivo, seu endereço IP, configuração de idioma do seu dispositivo), para fornecer conteúdo personalizados;
      A tabela abaixo descreve os dados pessoais coletados com terceiros

      Coletamos dados pessoais sobre o Usuário a partir de diversos terceiros. Estas fontes (terceiros) variam ao longo do tempo e incluem o seguinte:

      Categorias das fontes (terceiros)	Descrição da categoria
      Parceiros de autenticação	Se o Usuário assinar ou acessar nossos serviços usando credenciais de terceiros (como, por exemplo, Google, Facebook e Apple), importaremos suas informações a partir de terceiros para criação de sua conta conosco.
      Parceiros de serviços técnicos	Trabalhamos com parceiros de serviços técnicos que nos fornecem dados tais como o mapeamento de endereços IP para dados de localização imprecisa (cidade, estado e etc.), que nos permitem fornecer os Serviços, conteúdo e funcionalidades.
      <Typography variant='subtitle1'>
        5. Para que fins utilizamos os seus dados pessoais?
      </Typography>
      Quando o Usuário utiliza ou interage com os Serviços, diversas tecnologias são utilizadas para processar os dados pessoais coletados, por várias razões. A lista abaixo contém os motivos pelos quais o GIMOV processa os dados pessoais do Usuário, conforme descrito na Seção 4 "Como coletamos os seus dados pessoais?”:
      Prestar e personalizar os Serviços
      Execução de um Contrato
      Interesse Legítimo
      Dados de Usuário
      Dados de Utilização
      Entender, diagnosticar, solucionar e corrigir problemas dos Serviços.
      Execução de um Contrato
      Avaliar e desenvolver novas funcionalidades, tecnologias e melhorias para os Serviços.
      Finalidades de marketing, promoção e publicidade.
      Cumprir as obrigações legais e os pedidos de observância da lei.
      Dados relativos a Pesquisas
      Efetuar, exercer ou defender ações judiciais.
      Conduzir o planejamento de negócios, relatórios e previsões.
      Detectar fraudes, incluindo pagamentos fraudulentos e uso fraudulento dos Serviços.
      Execução de um Contrato
      Cumprimento de Obrigações Legais
      Conduzir pesquisas e estudos
      Para informações adicionais sobre o teste de equilíbrio realizado pelo GIMOV para garantir a observância dos direitos ao abrigo da LGPD, consulte a Seção 13 “Como entrar em contato conosco?”, bem como para informações adicionais sobre como entrar em contato conosco.
      <Typography variant='subtitle1'>
        6. Por quanto tempo armazenamos os seus dados pessoais?
      </Typography>
      Os dados coletados e os registros de atividades serão armazenados em ambiente seguro e controlado pelo prazo mínimo estipulado, conforme abaixo:

      Dados Pessoais	Prazo de Armazenamento	Fundamento Legal
      Dados Cadastrais	5 anos após o término da relação	Arts. 12 e 34 do Código de Defesa do Consumidor
      Dados de Identificação Digital	6 meses	Art. 15 do Marco Civil da Internet
      Se não existir um requisito legal, os dados serão armazenados enquanto forem necessários ao cumprimento de cada finalidade de tratamento. Caso haja solicitação do Usuário, os dados poderão ser apagados antes desse prazo. No entanto, pode ocorrer de os dados precisarem ser mantidos por período superior, por motivo de lei, ordem judicial, prevenção à fraude (art. 11, II, “a”, LGPD), proteção ao crédito (art. 7º, X, LGPD) e outros interesses legítimos, em conformidade com o art. 10 da LGPD. Findo o prazo e a necessidade legal, serão excluídos com uso de métodos de descarte seguro, ou utilizados de forma anônima para fins estatísticos.

      No caso dos dados de acesso e de geolocalização, os mesmos poderão ser conservados até o final do período de seu contrato ou, em caso de um usuário registrado em nossos sites, enquanto tal registro se mantiver ativo.
      <Typography variant='subtitle1'>
        7. Compartilhamento dos seus dados pessoais
      </Typography>
      Os dados pessoais do Usuário não serão compartilhados com terceiros, salvo autoridades policiais, órgãos governamentais, autoridades reguladoras, tribunais ou outras autoridades públicas, quando assim for determinado pela respectiva autoridade ou previamente autorizado pelo Usuário, nos termos da legislação vigente.
      <Typography variant='subtitle1'>
        8. Retenção e eliminação de dados
      </Typography>
      Procedemos à manutenção dos dados pessoais do Usuário apenas quando necessário para efeitos de fornecimento dos Serviços e para fins comerciais legítimos e essenciais, tais como manter o desempenho dos Serviços, tomar decisões comerciais com base em dados sobre novas funcionalidades e ofertas, cumprir as nossas obrigações legais e resolver litígios.

      Preservamos alguns dos seus dados pessoais enquanto usuário do GIMOV.

      Se assim o Usuário desejar, eliminaremos ou tornaremos anônimos os seus dados pessoais para que o mesmo não seja identificado, a menos que sejamos legalmente autorizados ou obrigados a manter determinados dados pessoais, inclusive nas seguintes situações:

      Onde se revele necessário reter os dados pessoais para efeitos das nossas obrigações legais, fiscais, de auditoria e contabilísticas, procederemos à retenção dos dados pessoais necessários durante o período exigido pela lei aplicável; e/ou,
      Sempre que necessário em função dos nossos interesses comerciais legítimos, tais como a prevenção de fraudes ou a manutenção da segurança dos nossos usuários.
      Os dados anonimizados não serão considerados dados pessoais, salvo quando o processo de anonimização ao qual foram submetidos for revertido, nos termos da lei.
      <Typography variant='subtitle1'>
        9. Transmissão para outros países
      </Typography>
      O GIMOV não compartilha os seus dados pessoais com outras empresas. Os seus dados pessoais podem, portanto, estar sujeitos a leis de privacidade distintas daquelas aplicáveis em seu país.

      Os dados pessoais coletados no Brasil podem, por exemplo, ser transmitidos e processados por terceiros localizados em um país fora do Brasil. Nesses casos, o GIMOV deve garantir que a transmissão dos dados pessoais do Usuário seja realizada de acordo com as leis de privacidade aplicáveis e, em particular, que medidas contratuais, técnicas e organizacionais adequadas estejam em vigor.

      Os dados coletados e tratados pelos Serviços poderão ser armazenados nos servidores de empresa subcontratada, cuja sede fique fora do País.

      Ainda que utilizando infraestrutura computacional administrada por terceiros, os Serviços não autorizam o uso dos dados pessoais dos seus Usuários fora do escopo de sua contratação.

      Para mais informações sobre as medidas de segurança utilizadas para proteger os seus dados pessoais, consulte Seção 11 "Manutenção da segurança dos seus dados pessoais" da presente Política.
      <Typography variant='subtitle1'>
        10. Links
      </Typography>
      Podemos exibir conteúdos que contenham links para sites de terceiros (como, por exemplo, Youtube e Google Maps). Não podemos controlar ou nos responsabilizar pelas práticas de privacidade e conteúdos de terceiros. Se o Usuário clicar em um link de terceiro, estará saindo dos Serviços, e quaisquer de seus dados pessoais não serão cobertos pela presente Política. Leia as respectivas políticas de privacidade para compreender de que forma tais terceiros coletam e processam os seus dados pessoais.
      <Typography variant='subtitle1'>
        11. Manutenção da segurança dos seus dados pessoais
      </Typography>
      Sempre que dados pessoais são armazenados em nossos sistemas, estes ficam protegidos contra o acesso ou uso não autorizado de terceiros. O GIMOV se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.

      A senha protege a sua conta de usuário, e, portanto, sugerimos que o Usuário utilize uma senha forte, que seja exclusiva da sua conta GIMOV, nunca compartilhando a mesma com ninguém, limitando o acesso ao seu Dispositivo e navegador, e saindo de sua conta após utilização dos Serviços.

      Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do Usuário.

      Os Serviços utilizam certificado SSL (Secure Socket Layer), que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o Usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.

      No entanto, o GIMOV se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do Usuário, como no caso em que ele mesmo transfere seus dados a terceiro, considerando que o GIMOV não terá qualquer forma de controle ou ingerência sobre os mesmos. O GIMOV se compromete, ainda, a comunicar o Usuário em prazo adequado, caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.

      A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizados a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.

      Por fim, o GIMOV se compromete a tratar os dados pessoais do Usuário com confidencialidade, dentro dos limites legais.
      <Typography variant='subtitle1'>
        12. Alterações na presente Política
      </Typography>
      Poderemos ocasionalmente efetuar alterações na presente Política.

      Quando realizarmos alterações relevantes na presente Política, o Usuário será devidamente notificado, conforme as circunstâncias, por exemplo, exibindo um aviso nos Serviços ou enviando-lhe um e-mail e/ou mensagem para o dispositivo. O GIMOV poderá notificar o Usuário com antecedência.

      Certifique-se, pois, de ler atentamente qualquer aviso desta natureza.
      <Typography variant='subtitle1'>
        13. Como entrar em contato?
      </Typography>
      Obrigado por ler a nossa Política.

      Se tiver alguma dúvida sobre a presente Política, entre em contato com o nosso Encarregado pela Proteção de Dados Pessoais através no e-mail para cleber@cleberdemetrio.adv.br, ou whatsapp (51)981925862.
      GIMOV
      © 2023 GATE 51 DESENVOLVIMENTO DE SISTEMAS DE INFORMAÇÃO LTDA - Todos direitos reservados
      POLÍTICAS DE PRIVACIDADE•TERMOS DE USO

    </Box>

  )
}
