import { plans } from '../constants/plans'

export function getPerfilByLicence(licence) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em getPerfilByLicence:', licence.licenca.toLocaleLowerCase()) }
  let userRules = []
  let userPermissions = []
  if (licence.licenca.toLowerCase() === plans.planUser.title.toLocaleLowerCase()) {
    userRules = [plans.planUser.rule.id]
    userPermissions = plans.planUser.rule.permissions
  } else if (licence.licenca.toLowerCase() === plans.planBroker.title.toLocaleLowerCase()) {
    userRules = [plans.planBroker.rule.id]
    userPermissions = plans.planBroker.rule.permissions
  } else if (licence.licenca.toLowerCase() === plans.planAdvanced.title.toLocaleLowerCase()) {
    userRules = [plans.planAdvanced.rule.id]
    userPermissions = plans.planAdvanced.rule.permissions
  } else if (licence.licenca.toLowerCase() === plans.planBusiness.title.toLocaleLowerCase()) {
    userRules = [plans.planBusiness.rule.id]
    userPermissions = plans.planBusiness.rule.permissions
  } else {
    return null
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('licenca:', licence.licenca.toLocaleLowerCase()) }
  return {
    userRules,
    userPermissions
  }
}