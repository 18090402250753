import { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import { CadEquipe } from './cadequipe/CadEquipe';
import { CadConvite } from './cadconvite/CadConvite';
import { getAllOperadoresEquipe } from '../../controllers/equipeController';
import { CadMensagem } from './cadmensagem/CadMensagem';

export default function Equipe({ user, openEquipe, setOpenEquipe, setHasMessage, setMsgAdm, msgAdm }) {

  const conta = user.conta
  const [value, setValue] = useState('1');
  const [listUsuarios, setListUsuarios] = useState([])

  const handleClose = () => {
    setOpenEquipe(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  async function getAllOperadores() {
    const operadores = await getAllOperadoresEquipe(conta)
    setListUsuarios(operadores)
  }

  useEffect(() => {
    getAllOperadores()
  }, [])

  return (
    <Dialog
      open={openEquipe}
      onClose={handleClose}
      maxWidth='md'
    >
      <Grid container height={'80%'} direction={'column'} >
        <DialogTitle>Cadastro da Equipe</DialogTitle>
        <DialogContent >
          <DialogContentText></DialogContentText>
          <TabContext value={value}>
            <Grid item width={'700px'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="">
                <Tab label="Equipe" value="1" />
                <Tab label="Convite" value="2" />
                <Tab label="Quadro de Avisos" value="3" />
              </TabList>
            </Grid>
            <TabPanel value="1">
              <CadEquipe
                user={user}
                listUsuarios={listUsuarios}
                setListUsuarios={setListUsuarios}
                getAllOperadores={getAllOperadores}
              />
            </TabPanel>
            <TabPanel value="2">
              <CadConvite
                user={user}
                listUsuarios={listUsuarios}
                setListUsuarios={setListUsuarios}
              />
            </TabPanel>
            <TabPanel value="3">
              <CadMensagem user={user}
                setHasMessage={setHasMessage}
                setMsgAdm={setMsgAdm}
                msgAdm={msgAdm} />
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
        </DialogActions>
        <Grid container justifyContent={'end'}>
          <Button sx={{ mr: 2 }}
            onClick={handleClose}
          >Fechar</Button>
        </Grid>
      </Grid>
    </Dialog>

  )
}