import { Grid } from '@mui/material';

export function LandingPage() {

  return (
    <Grid container
      width={'100vw'}
      // height={'100vh'}
      alignItems={'center'}
      direction={'column'}
      justifyContent={'center'}
      position={'relative'}

      sx={{
        justifyContent: 'center',
        backgroundColor: '#1D2332',
        // backgroundImage: 'url(/assets/images/landing_mobile1.jpeg)',
        // backgroundRepeat: 'no-repeat',
        // opacity: '1',
        // backgroundSize: 'contain'
      }}
    >
      <Grid
        item
        height={'100%'}
      // width={'1024px'}
      >
        <img
          src='/assets/images/landing_mobile1.jpeg'
          style={{
            opacity: 1, position: 'relative',
            left: '10%', right: '10%', 
            // top: 0,
            width: '80%', height: '100%'
          }}
          // width={'100%'}
          // height={'100%'}
          alt=''
        />
        <img
          src='/assets/images/landing_mobile1.jpeg'
          style={{
            opacity: 1, position: 'relative',
            left: '10%', right: '10%', 
            // top: 1024,
            width: '80%', height: '100%'
          }}
          // width={'100%'}
          // height={'100%'}
          alt=''
        />
      </Grid>

    </Grid>

  )
}