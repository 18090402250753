import { collection, deleteDoc, query, getDocs, doc, addDoc, getDoc, setDoc, updateDoc, runTransaction, where, serverTimestamp, Query, Timestamp, FieldValue, arrayUnion, arrayRemove, writeBatch, getCountFromServer } from 'firebase/firestore';
import { db } from '../firebase';
import { result } from '../utils/result';
import { getContaController } from './contaController';
import { getUserControl } from './userController';
import { canUserJoinTeam } from '../constants/perfil';
import { rules } from '../constants/rules';



export async function leaveTheTeam(_idconta, _idoperador) {
  const batch = writeBatch(db)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em leaveTheTeam', _idconta, _idoperador)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const docRef = doc(db, 'contas/' + _idconta + '/operadores/' + _idoperador)
    batch.delete(docRef)
    const docRef2 = doc(db, 'usuarios/' + _idoperador)
    batch.update(docRef2, { conta: arrayRemove(_idconta) })
    await batch.commit();
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em leaveTheTeam', e)
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de leaveTheTeam', result)
    return result
  }

}

export async function getMessage(_conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em getMesagemConta', _conta)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    let list = []
    const q = query(collection(db, "contas/" + _conta + '/mensagens'));
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      let msg = doc.data()
      msg.id = doc.id
      list.push(msg)
    });
    result.data = list
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getMesagemConta', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getMesagemConta', result)
    return result
  }
}

export async function deleteMessage(_idmsg, _conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em deleteMesagem', _conta)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const docRef = doc(db, 'contas/' + _conta + '/mensagens/' + _idmsg)
    await deleteDoc(docRef)
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deleteMesagem', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de deleteMesagem', result)
    return result
  }
}


export async function saveMessage(_idmsg, user, _mensagem) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setMensagem', _idmsg, user, _mensagem)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    if (_idmsg) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('setMensagem - Update')
      const docRef = doc(db, 'contas/' + user.conta + '/mensagens/' + _idmsg)
      await updateDoc(docRef, { user: user.id, nome: user.nome, msg: _mensagem, createdat: serverTimestamp() })
      result.data = { idmsg: _idmsg, user: user.id, nome: user.nome, msg: _mensagem }
    }
    else {
      const msg = {
        user: user.id,
        nome: user.nome,
        msg: _mensagem,
        ativo: true,
        createdat: serverTimestamp()
      }

      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('setMensagem - Insert')
      const colRef = collection(db, 'contas/' + user.conta + '/mensagens')
      const idmsg = (await addDoc(colRef, msg)).id
      msg.idmsg = idmsg
      result.data = msg
    }
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setMensagem', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setMensagem', result)
    return result
  }

}


export async function saveEquipeNome(_conta, _nome) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setNomeEquipe', _conta, _nome)
  try {
    result.sucess = true
    result.msg = 'Operação realizada com sucesso...'
    result.data = null
    const docRef = doc(db, 'contas/' + _conta)
    await updateDoc(docRef, { equipe: _nome })
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...'
    result.data = { e }
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setNomeEquipe', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setNomeEquipe', result)
    return result
  }
}

export async function saveOperador(_operador, _conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setOperador:', _operador)
  try {
    const opConta = {
      id: _operador.id,
      nome: _operador.nome,
      email: _operador.email,
      rules: [rules.ruleViewer.title],
      permissions: rules.ruleViewer.permissions,
      ativo: true,
      createdat: serverTimestamp()
    }
    const docRefOperador = doc(db, 'contas/' + _conta + '/operadores/' + _operador.id)
    await setDoc(docRefOperador, opConta)
    result.sucess = true
    result.msg = 'Operador cadastrado com sucesso...'
    result.data = null
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação de cadastro...'
    result.data = null
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setOperador:', _operador, e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setOperador:', result)
    return result
  }

}
export async function getQtdOperadores(_conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em getQtdOperadores', _conta) }
  try {
    const coll = collection(db, "contas/" + _conta + '/operadores')
    const snapshot = await getCountFromServer(coll)
    result.sucess = true
    result.msg = 'Sucesso na operação...'
    result.data = snapshot.data().count
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getQtdOperadores', snapshot.data().count)
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação...' + e.message
    result.data = -1
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getQtdOperadores', e)
  }
  finally {
    return result
  }
}
export async function getCanJoinTeam(_conta) {
  const ctaTerceiro = await getUserControl(_conta)
  if (ctaTerceiro) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('terceiro.plano', ctaTerceiro.plano)
    const rst = await getQtdOperadores(_conta)
    if (rst.sucess) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('qtd operadores conta.terceiro', rst.data)
      if (canUserJoinTeam(rst.data, ctaTerceiro.plano)) {
        result.sucess = true
        result.msg = 'Ok.'
        result.data = rst
      }
      else {
        result.sucess = false
        result.msg = 'Limite de operadores alcançado para essa equipe! Informe o administrador da equipe.'
        result.data = null
      }
      
    }
    else {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getCanJoinTeam', rst)
      return rst
    }
  }
  else {
    result.sucess = false
    result.msg = 'Conta de equipe inválida...'
    result.data = null
  }
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getCanJoinTeam', result)
  return result

}

export async function getAllOperadoresEquipe(_conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou em getAllOperadoresEquipe', _conta) }
  let operadores = [];
  try {
    const q = query(collection(db, "contas/" + _conta + '/operadores'));
    const queryData = await getDocs(q);
    queryData.forEach((doc) => {
      let oper = doc.data()
      oper.idop = doc.id
      operadores.push(oper)
    });
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de getAllOperadoresEquipe', operadores)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em getAllOperadoresEquipe', e)
  }
  finally {
    return operadores
  }
}

export async function setOperadorController(operador, conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setOperadorController', operador)
  try {
    const docRef = doc(db, 'contas/' + conta + '/operadores/', operador.idop)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Gerou docRef', docRef)

    if (operador.rules.includes('consulta')) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Perfil Consulta')
      await updateDoc(docRef,
        // { permissions: arrayRemove('write'), rules: ['consulta'] }
        { permissions: rules.ruleViewer.permissions, rules: [rules.ruleViewer.title] }
      )
    }
    if (operador.rules.includes('cadastro')) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Perfil Cadastro')
      await updateDoc(docRef,
        // { permissions: arrayUnion("write"), rules: ['cadastro'] }
        { permissions: rules.ruleOperator.permissions, rules: [rules.ruleOperator.title] }
      )
    }
    return true
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setOperadorController', e)
  }
}

async function deleteOperatorController(conta, idop) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em deleteOperadorController')
  const docRef = doc(db, 'contas/' + conta + '/operadores/', idop)
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Query A:', docRef)
  // Remove o Operador da Conta de Trabalho
  await deleteDoc(docRef)
}

export async function removeOperatorController(conta, idop, id) {
  try {
    // Remove o Operador da Conta de Trabalho
    await deleteOperatorController(conta, idop)

    // if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em deleteOperadorController')
    // const docRef = doc(db, 'contas/' + conta + '/operadores/', idop)
    // if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Query A:', docRef)
    // // Remove o Operador da Conta de Trabalho
    // await deleteDoc(docRef)

    // Remove o convite e a conta de trabalho do Usuário
    const docUserRef = doc(db, 'usuarios/' + id)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Query B:', docUserRef)
    await updateDoc(docUserRef, { conta: arrayRemove(conta), convite: '' })

  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deleteOperadorController', e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu em deleteOperadorController')
    return true
  }
}
