
export class userModel {
  constructor(
    id,
    conta,
    nome,
    email,
    photourl,
    tppfpj,
    ufcreci,
    nrcreci,
    convite,
    permissions,
    rules,
    plano,
    ativo,
    createdat,
    cpfcnpj,
    telefone,
    iswhatsapp,
    issms,
    cep,
    logradouro,
    nrimovel,
    complemento,
    bairro,
    ufend,
    municipio,
    updatedat,
    credito
  ) {
    this.id = id
    this.conta = conta
    this.nome = nome
    this.email = email
    this.photourl = photourl
    this.tppfpj = tppfpj
    this.ufcreci = ufcreci
    this.nrcreci = nrcreci
    this.convite = convite
    this.permissions = permissions
    this.rules = rules
    this.plano = plano
    this.ativo = ativo
    this.createdat = createdat
    this.cpfcnpj = cpfcnpj
    this.telefone = telefone
    this.iswhatsapp = iswhatsapp
    this.issms = issms
    this.cep = cep
    this.logradouro = logradouro
    this.nrimovel = nrimovel
    this.complemento = complemento
    this.bairro = bairro
    this.ufend = ufend
    this.municipio = municipio
    this.updatedat = updatedat
    this.credito = credito
  }
  setData(data, id) {
    this.id = id
    this.conta = data.conta;
    this.nome = data.nome;
    this.email = data.email
    this.photourl = data.photourl
    this.tppfpj = data.tppfpj
    this.ufcreci = data.ufcreci
    this.nrcreci = data.nrcreci
    this.convite = data.convite
    this.permissions = data.permissions
    this.rules = data.rules
    this.plano = data.plano
    this.ativo = data.ativo
    this.createdat = data.createdat
    this.cpfcnpj = data.cpfcnpj
    this.telefone = data.telefone
    this.iswhatsapp = data.iswhatsapp
    this.issms = data.issms
    this.cep = data.cep
    this.logradouro = data.logradouro
    this.nrimovel = data.nrimovel
    this.complemento = data.complemento
    this.bairro = data.bairro
    this.ufend = data.ufend
    this.municipio = data.municipio
    this.updatedat = data.updatedat
    this.credito = data.credito
  }
  toString() {
    return this.id + ', ' + this.conta + ', ' + this.nome + ', ' + this.email + ', ' + this.photourl +
      ',' + this.tppfpj + ', ' + this.ufcreci + ', ' + this.nrcreci + ', ' + this.convite + ', ' + this.permissions +
      ',' + this.rules + ', ' + this.plano + ', ' + this.ativo + ', ' + this.createdat +
      ',' + this.cpfcnpj + ',' + this.telefone + ',' + this.iswhatsapp + ',' + this.issms +
      ',' + this.cep + ',' + this.logradouro + ',' + this.nrimovel + ',' + this.complemento +
      ',' + this.bairro + ',' + this.ufend + ',' + this.municipio + ',' + this.updatedat + ',' + this.credito
  }
}

// Firestore data converter
export const userConverter = {
  toFirestore: (user) => {
    return {
      id: user.id,
      conta: user.conta,
      nome: user.nome,
      email: user.email,
      photourl: user.photourl,
      tppfpj: user.tppfpj,
      ufcreci: user.ufcreci,
      nrcreci: user.nrcreci,
      convite: user.convite,
      permissions: user.permissions,
      rules: user.rules,
      plano: user.plano,
      ativo: user.ativo,
      createdat: user.createdat,
      cpfcnpj: user.cpfcnpj,
      telefone: user.telefone,
      iswhatsapp: user.iswhatsapp,
      issms: user.issms,
      cep: user.cep,
      logradouro: user.logradouro,
      nrimovel: user.nrimovel,
      complemento: user.complemento,
      bairro: user.bairro,
      ufend: user.ufend,
      municipio: user.municipio,
      updatedat: user.updatedat,
      credito: user.credito
    }
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new userModel(
      data.id,
      data.conta,
      data.nome,
      data.email,
      data.photourl,
      data.tppfpj,
      data.ufcreci,
      data.nrcreci,
      data.convite,
      data.permissions,
      data.rules,
      data.plano,
      data.ativo,
      data.createdat,
      data.cpfcnpj,
      data.telefone,
      data.iswhatsapp,
      data.issms,
      data.cep,
      data.logradouro,
      data.nrimovel,
      data.complemento,
      data.bairro,
      data.ufend,
      data.municipio,
      data.updatedat,
      data.credito
    )
  }
}






