import { useTheme } from '@mui/material'

const theme = useTheme;

export const formControl = () => {
  return {
    margin: theme.spacing(1), minWidth: 120, marginBottom: '30px',
  }
}

export const paper = () => {
  return {
    padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100px',
  }
}
export const mapContainer = () => {
  return {
    height: '85vh', width: '100%',
  }
}
export const markerContainer = () => {
  return {
    position: 'absolute', transform: 'translate(-50%, -50%)', zIndex: 1, '&:hover': { zIndex: 2 },
  }
}
export const pointer = () => {
  return {
    cursor: 'pointer',
  }
}

export const divStyle = {
  //background: 'white',
  //border: '1px solid #ccc',
  
  width:120,
  
}

export const InfoWindow={
  //width:'500px',
  //zIndex:10
}