

export const chip = () => {
  return {
    margin: '0px px 0px 0'
  }
}

export const subtitle = () => {
  return {
    display: 'flex', alignItems: 'center', 
  }
}

export const spacing = () => {
  return {
    display: 'flex', alignItems: 'center', justifyContent: 'space-between',
  }
}
