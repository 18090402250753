import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { deleteMessage, getMessage, saveMessage } from '../../../controllers/equipeController';
import { useEffect, useState } from 'react';

export function CadMensagem({ user, setHasMessage, setMsgAdm, msgAdm }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em CadMensagem', user)

  const [msg, setMsg] = useState()
  const [idMsg, setIdMsg] = useState()
  const [openExcluir, setOpenExcluir] = useState(false)

  async function handleGravar() {
    if (msg) {
      if (msg.length > 0) {
        const rst = await saveMessage(idMsg, user, msg)
        if (rst.sucess) {
          setHasMessage(true)
          setMsgAdm(rst.data)
          setIdMsg(rst.data.idmsg)
        }
      }
    }
  }

  async function loadMensagens(_conta) {
    let rst = await getMessage(_conta)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        setIdMsg(rst.data[0].id)
        setMsg(rst.data[0].msg)
      }
    }
  }

  async function excluirMensagem() {
    let rst = await deleteMessage(idMsg, user.conta)
    if (rst.sucess) {
      setIdMsg(null)
      setMsgAdm(null)
      setHasMessage(false)
      setMsg('')
    }
    setOpenExcluir(false)
  }

  useEffect(() => {
    loadMensagens(user.conta)
  }, [])

  return (
    <Grid
      container
      direction={'row'}
      // height={'500px'}
      justifyContent={'flex-start'}
    >
      <Typography variant='h6' my={2} >Quadro de Avisos</Typography>
      <TextField
        fullWidth
        id="outlined-multiline-static"
        label=""
        onBlur={(event) => { setMsg(event.target.value) }}
        multiline
        rows={15}
        defaultValue={msg}
        size='small'
        inputProps={{ maxLength: 1000 }}
      />
      <Grid container justifyContent={'end'} my={1}>
        <LoadingButton
          id='btExcluir'
          size="small"
          color="primary"
          onClick={() => setOpenExcluir(true)}
          // loading={isLoadingExc}
          loadingPosition="start"
          variant="contained"
          startIcon={<DeleteTwoToneIcon />}
          disabled={msg?false:true}
          sx={{ width: '100px', mx: 1 }}
        > Excluir
        </LoadingButton>
        <LoadingButton
          id='btGravar'
          size="small"
          color="primary"
          onClick={handleGravar}
          // loading={isLoadingUser}
          loadingPosition="start"
          variant="contained"
          startIcon={<SaveTwoToneIcon />}
          sx={{ width: '100px' }}
        >Gravar
        </LoadingButton>
      </Grid>
      {/* Dialog Desativar Código do Convite */}
      <Dialog
        open={openExcluir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant='button'>Confirmar Exclusão:</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant='body1' component={'span'} color={'error'}>
              Excluir a mensagem?
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={excluirMensagem}
            disabled={idMsg ? false : true}
          >Sim</Button>
          <Button
            onClick={() => setOpenExcluir(false)}
          >Não</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}