


export const places_example = [
  {
    id: 'gimov', 
    academia: true,
    andares: "10",
    blocos: "1",
    churrasqueira: true,
    cinema: false,
    construtora: "GIMOV",
    coworking: false,
    createdat: null,
    descricao: "176 – Unidades\n01 – Torres\n70% Vagas cobertas pelo menos + 30% de vagas descobertas e demarcadas\n17 Unidades com Suíte – Somente 01 por andar\n26 Apartamentos Gardens – com Pátio Privativo\nOpção de Apartamentos de 01 dormitório\n18 itens de Lazer e equipamentos para o condomínio\n03 Elevadores\n5.800 m2 de área de Terreno\nJanelas dormitórios com Persianas de PVC ou alumínio – Tipo de enrolar nos\ndormitórios",
    divulgacao: "https://drive.google.com/drive/folders/1dH2g_-uMh8pETk2lCcgm1WZCAyLVrJfN",
    drive: "https://drive.google.com/drive/folders/1YWp5i6HYHIFDUkX4UUYJFAn6VFuXUkao",
    elevador: true,
    empreendimento: "Residencial GIMOV - Exemplo",
    endereco: "Av. Atlantica, 1450, Cambúriu, SC, BR",
    entrega: "Maio de 2026",
    espacokids: true,
    esportebar: false,
    gardem: true,
    gourmet: true,
    idcad: "8ryG2pTmV3dq9yoz1QVs",
    implantacao: "https://drive.google.com/drive/folders/1SgBlbUHpwp5ajgfKQ83jsb3o1_dZ1ukr",
    lavanderia: true,
    marketplace: false,
    maxarea: "110.00",
    memorial: "https://drive.google.com/drive/folders/1B68GjF6U_csUKIJoGiDvOM5nMKfgqKxm",
    minarea: "58.00",
    petcare: false,
    petplace: true,
    piscina: true,
    plantas: "https://drive.google.com/drive/folders/1tCBSCXjNvI4UOwqHfLKbKpSqKkBzcX26",
    playgroud: true,
    position: { lat: -26.990326402133004, lng: -48.629489489182156 },
    promocao: "ITBI \nAceita Imóveis como parte do pagamento.\nPiso na Sala para as primeiras 10 unidades.\nPara o Corretor + 2%\nPara o Corretor viagem SPA\n\n\n\n",
    qdesportiva: true,
    qdtenis: false,
    qdvolei: false,
    quartosqtdmax: 3,
    quartosqtdmin: 1,
    quiosquechur: false,
    refvlrano: "2023",
    refvlrmes: "09",
    renda: "R$ 2.500,00",
    rooftop: false,
    sacada: true,
    salafesta: true,
    salajogos: true,
    salamult: false,
    sauna: false,
    site: "www.gimov.com.br",
    situacao: "EO",
    tbvendas: "https://drive.google.com/drive/folders/1pj9tf-61NZ8d7_SMKrOUqjoqQ2oaafpV",
    telefone: "(51) 313131313",
    urlimagem: "https://www.tiosamimoveis.com.br/wp-content/uploads/2021/01/6f04fdd3-c5cf-4b43-bb34-2d7ac3eab944.jpg",
    vagafixa: true,
    vagarotativa: false,
    vlrfinal: "R$ 500.000,00",
    vlrinicial: "R$ 255.000,00",
  }]
