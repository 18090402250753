import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MoreIcon from '@mui/icons-material/MoreVert';

import { forwardRef, useState } from 'react';
import { SingOut } from '../../public/Login/SingOut';
import { useNavigate } from 'react-router-dom';
import { Grid, Slide, Typography } from '@mui/material';

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import QuestionMarkTwoToneIcon from '@mui/icons-material/QuestionMarkTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import './styles.css'
import { rules } from '../../constants/rules';
import { green, red } from '@mui/material/colors';
import { canEdit, canFilter, canTeam } from '../../constants/perfil';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(3),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: 'inherit',
//   '& .MuiInputBase-input': {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('md')]: {
//       width: '20ch',
//     },
//   },
// }));



export default function MenuAppBar(
  { setAddMap, addMap, setActiveMarker,
    user, setCoordinates, setIsPageAjuda,
    isPageAjuda, setOpenPerfil, openPerfil,
    setOpenEquipe, openEquipe, hasConvite,
    setOpenConvite, setPosAtual, hasMessage, setOpenMessage,
    setOpenFilter, isFiltering, setIsFiltering, isExample }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Menu:', addMap, user)

  let navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [teamAnchoerEl, setTeamAnchorEl] = useState(null)


  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isTeamMenuOpen = Boolean(teamAnchoerEl)


  function handleOpenFiltro() {
    setOpenFilter(!isFiltering)
    setIsFiltering(!isFiltering)
    setActiveMarker(null)
    setAddMap(false)
  }
  const handleClickAjuda = () => {
    setIsPageAjuda(!isPageAjuda)
  }


  const handleClickGPS = (event) => {
    navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Carregando Coordinates:', latitude, longitude)
      setCoordinates({ lat: latitude, lng: longitude });
      setPosAtual({ lat: latitude, lng: longitude })
    });
  }

  const handleAddMap = (event) => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleAddMap:', addMap, event)
    setActiveMarker(null)
    setAddMap(!addMap)
    
  }

  const handleOpenPerfil = () => {
    setOpenPerfil(true)
    setAnchorEl(null);
  }
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTeamMenuOpen = (event) => {
    setTeamAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuLogOut = (event) => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleMenuLogOut', event)
    SingOut();
    navigate('/singin', { replace: true })
  }
  const handleMenuSingOut = (event) => {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em HandleMenuSingOut', event)
    SingOut();
    navigate('/singin', { replace: true })
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });

  // Menu Suspenso Desktop
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{'Usuário: ' + user.email}</MenuItem>
      {user.conta !== user.id && (<MenuItem onClick={handleMenuClose}>{'Equipe: ' + user.equipe}</MenuItem>)}
      {/* <MenuItem onClick={handleMenuClose}>{'Plano: ' + user.plano}</MenuItem> */}
      <MenuItem onClick={handleOpenPerfil} >Minha Conta</MenuItem>
      {/* <MenuItem onClick={handleMenuSingOut}>SingOut</MenuItem> */}

    </Menu>
  );

  // Menu Suspenso Mobile
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <IconButton
          size="large"
          aria-label="Perfil"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={handleMenuSingOut}>
        <IconButton
          size="large"
          aria-label="Sair"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppTwoToneIcon />
        </IconButton>
        <p>LogOut</p>
      </MenuItem>
    </Menu>
  );

  // Menu Suspenso Team

  const handleTeamMenuClose = () => {
    setTeamAnchorEl(null);
  };


  const handlerOpenEquipe = () => {
    setOpenEquipe(!openEquipe)
    handleTeamMenuClose()
  }

  const teamMenuId = 'team-menu';
  const renderTeamMenu = (
    <Menu
      anchorEl={teamAnchoerEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={teamMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isTeamMenuOpen}
      onClose={handleTeamMenuClose}
    >
      <MenuItem onClick={handlerOpenEquipe} >Equipe</MenuItem>
    </Menu>
  );

  return (

    <Grid container width={'100vw'} height={'7vh'}>
      <AppBar position="fixed" >
        <Toolbar  >
          <Grid container
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {/* Logotipo */}
            <Grid
              height={'30px'}
              width={'120px'}
              sx={{
                backgroundImage: 'url(./logo2.svg)',
                backgroundRepeat: 'no-repeat',
                // backgroundColor:'red'
              }} />
            {/* --------> Menu Desktop <----------*/}

            <Grid sx={{
              display: { xs: 'none', md: 'flex' },
            }}>
              {/* <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Pesquisar..."
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search> */}




              {/* Icone do Menu para AJUDA} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="Ajuda"
                color={isPageAjuda || isExample ? 'error' : 'inherit'}
                onClick={handleClickAjuda}
                sx={{ mx: '10px' }}
                className={isPageAjuda ? '' : isExample ? 'btn-pisca' : ''}
              >
                <QuestionMarkTwoToneIcon fontSize='large' />
              </IconButton>

              {/* Icone do Menu para administrar Convites} */}
              {user.hasinvite && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Equipe"
                  aria-controls={teamMenuId}
                  aria-haspopup="true"
                  onClick={() => { setOpenConvite(true) }}
                  color="error"
                  sx={{ mx: '10px' }}
                  className='btn-pisca'
                >
                  <EmailTwoToneIcon fontSize='large' />
                </IconButton>
              )}


              {/* Icone do Menu para MENSAGENS} */}
              {hasMessage && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Mensagem"
                  color='secondary'
                  onClick={() => { setOpenMessage(true) }}
                  sx={{ mx: '10px' }}
                >
                  <MessageTwoToneIcon fontSize='large' />
                </IconButton>
              )}

              {/* Icone do Menu para Filtro} */}
              {canFilter(user) && (
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="Ajuda"
                  color={isFiltering ? 'error' : 'inherit'}
                  onClick={handleOpenFiltro}
                  sx={{ mx: '10px', backgroundColor: isFiltering ? green[500] : '', borderRadius: 0 }}
                >
                  <FilterAltTwoToneIcon fontSize='large' />
                </IconButton>
              )}
              {/* Icone do Menu para GPS} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="GPS"
                color="inherit"
                onClick={handleClickGPS}
                sx={{ mx: '10px' }}
              >
                <GpsFixedTwoToneIcon fontSize='large' />
              </IconButton>
              {/* Icone do Menu para Administrar Equipes} */}
              {canTeam(user) && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Equipe"
                  aria-controls={teamMenuId}
                  aria-haspopup="true"
                  //  onClick={handleTeamMenuOpen}
                  onClick={() => { setOpenEquipe(!openEquipe) }}
                  color="inherit"
                  sx={{ mx: '10px' }}
                >
                  <GroupAddTwoToneIcon fontSize='large' />
                </IconButton>
              )}

              {/* Icone do Menu para Editar Imóveis} */}
              {canEdit(user) && (
                <IconButton
                  size="large"
                  aria-label="cadastrar"
                  color={addMap ? 'error' : 'inherit'}
                  onClick={handleAddMap}
                  sx={{ mx: '10px', backgroundColor: addMap ? green[500] : '', borderRadius: 0 }}
                  disabled={isFiltering}
                >
                  <EditTwoToneIcon fontSize='large' />
                </IconButton>
              )}

              {/* Icone do Menu para Perfil ddo Usuário} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="conta"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                sx={{ mx: '10px' }}
              >

                <AccountCircle fontSize='large' />
              </IconButton>
              {/* <Avatar sx={{ width: 32, height: 32 }} alt={user.nome} src="/assets/avatars/avatar2_32x32.png" /> */}


              {/* Icone do Menu para LogOut} */}
              <IconButton size="large"
                aria-label="sair"
                color="inherit"
                onClick={handleMenuLogOut}
                sx={{ ml: '10px' }}
              >
                <ExitToAppTwoToneIcon fontSize='large' />
              </IconButton>
            </Grid>

            {/* Menu Mobile */}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>

              {/* Icone do Menu para AJUDA} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="Ajuda"
                color={isPageAjuda || isExample ? 'error' : 'inherit'}
                onClick={handleClickAjuda}
                className={isPageAjuda ? '' : isExample ? 'btn-pisca' : ''}
              >
                <QuestionMarkTwoToneIcon />
              </IconButton>

              {/* Icone do Menu para MENSAGENS} */}
              {hasMessage && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Mensagem"
                  color='secondary'
                  onClick={() => { setOpenMessage(true) }}
                >
                  <MessageTwoToneIcon />
                </IconButton>
              )}

              {/* Icone do Menu para Filtro} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="Filtrar"
                color={isFiltering ? 'secondary' : 'inherit'}
                onClick={handleOpenFiltro}
              >
                <FilterAltTwoToneIcon />
              </IconButton>

              {/* Icone do Menu para administrar Convites} */}
              {user.hasinvite && (
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="Equipe"
                  aria-controls={teamMenuId}
                  aria-haspopup="true"
                  onClick={() => { setOpenConvite(true) }}
                  color="inherit"
                  sx={{ mx: '10px' }}
                >
                  <EmailTwoToneIcon fontSize='large' />
                </IconButton>
              )}
              <IconButton
                size="large"
                aria-label="Perfil"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                onClick={handleClickGPS}
              >
                <GpsFixedTwoToneIcon />
              </IconButton>

              <IconButton size="large"
                aria-label="sair"
                color="inherit"
                onClick={handleMenuLogOut}
              >
                <ExitToAppTwoToneIcon />
              </IconButton>

              {/* Icone do Menu para Perfil ddo Usuário} */}
              <IconButton
                size="large"
                edge="end"
                aria-label="conta"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >

                <AccountCircle fontSize='large' />
              </IconButton>
              {/* <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
              >
                <MoreIcon />
              </IconButton> */}
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderTeamMenu}
    </Grid>

  );
}