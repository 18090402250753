import React, { useState, useEffect } from 'react';

export const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text?.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>
};

export const Typewriter2 = ({ array, delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [indexArray, setIndexArray] = useState(0);
  useEffect(() => {
    for (let i = indexArray; i < array.length - 1; i++) {
      let text = array[indexArray]
      if (currentIndex < text.length) {
        const timeout = setTimeout(() => {
          setCurrentText(prevText => prevText + text[currentIndex]);
          setCurrentIndex(prevIndex => prevIndex + 1);
        }, delay);
        return () => clearTimeout(timeout);
      }
      setIndexArray(prev => prev + 1)
    }

  }, [currentIndex, currentText, delay, indexArray, array]);

  return <p >{currentText}</p>
};
