export function situacaoTipo(_sit) {
  let situacao = ''
  switch (_sit) {
    case 'EO':
      situacao = 'Em obras'
      break
    case 'OE':
      situacao = 'Em obras/Entregue'
      break
    case 'LA':
      situacao = 'Lançamento'
      break

    case 'E':
      situacao = 'Entregue'
      break
    case 'V':
      situacao = '100% Vendido'
      break
    default:
      situacao = ''
      break
  }
  return situacao
}