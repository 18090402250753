import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useRef } from 'react';

export function Mensagem({ openMessage, setOpenMessage, msgAdm }) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Mensagem', msgAdm)
  const descriptionElementRef = useRef(null);

  return (

    <Dialog
      open={openMessage}
      scroll='paper'
      aria-labelledby="title"
      aria-describedby="dialog"
      fullWidth
    >
      <DialogTitle id="title">Quadro de Avisos</DialogTitle>
      <DialogContent dividers >
        <DialogContentText
          id="dialog"
          ref={descriptionElementRef}
          tabIndex={-1}
          height={'400px'}
        >
          <TextField
            fullWidth
            id="standard-multiline-static"
            multiline
            rows={22}
            defaultValue={msgAdm.msg}
            variant="standard"
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      ( {msgAdm.nome} )
        <Button
          onClick={() => { setOpenMessage(false) }}
        >Fechar</Button>
      </DialogActions>

    </Dialog>
  )
}