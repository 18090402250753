// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from "firebase/storage";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC-F2BxF7tqI_8SyBfinjiGkX-ZMZlG0lU",
  authDomain: "gimov-987b8.firebaseapp.com",
  projectId: "gimov-987b8",
  storageBucket: "gimov-987b8.appspot.com",
  messagingSenderId: "89857785474",
  appId: "1:89857785474:web:bfe3bc57b088030c4cfe9b",
  measurementId: "G-HH5BGQ3ZV3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
auth.languageCode='pt-BR'
auth.useDeviceLanguage()


export const storage = getStorage(app);
export const db = getFirestore(app)

//const analytics = getAnalytics(app);



