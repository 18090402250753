import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, } from '@mui/material'
import { useEffect, useState } from 'react'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask'


export const Identificacao = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace,
  newPosition,
  openExcluirDialog,
  setOpenExcluirDialog,
  handleClickExcluir }) => {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no form Identificação', newPlace)

  let _place = placeEdit

  const [vEmpreendimento, setvEmpreendimento] = useState(false)
  const [vConstrutora, setvConstrutora] = useState(false)
  const [vEndereco, setvEndereco] = useState(false)

  const [id, setID] = useState(_place.hasOwnProperty('id') ? _place.id : newPlace.id)
  const [position, setPosition] = useState(_place.hasOwnProperty('position') ? _place.position : newPlace.position)
  const [empreendimento, setEmpreendimento] = useState(_place.hasOwnProperty('empreendimento') ? _place.empreendimento : newPlace.empreendimento)
  const [telefone, setTelefone] = useState(_place.hasOwnProperty('telefone') ? _place.telefone : newPlace?.telefone)
  const [construtora, setConstrutora] = useState(_place.hasOwnProperty('construtora') ? _place.construtora : newPlace?.construtora)
  const [site, setSite] = useState(_place.hasOwnProperty('site') ? _place.site : newPlace?.site)
  const [endereco, setEndereco] = useState(_place.hasOwnProperty('endereco') ? _place.endereco : newPlace?.endereco)
  const [entrega, setEntrega] = useState(_place.hasOwnProperty('entrega') ? _place.entrega : newPlace?.entrega)
  const [situacao, setSituacao] = useState(_place.hasOwnProperty('situacao') ? _place.situacao : newPlace?.situacao)
  const [descricao, setDescricao] = useState(_place.hasOwnProperty('descricao') ? _place.descricao : newPlace?.descricao)

  useEffect(() => {
    if (!empreendimento) setvEmpreendimento(true)
    if (!construtora) setvConstrutora(true)
    if (!endereco) setvEndereco(true)
  }, [])

  const style = {
    my: '7px',
    mr: '10px',
  }

  const hasError = (event) => {
    let res = false
    if (event.target.value.length <= 3) res = true
    if (event.target.id === 'construtora') setvConstrutora(res)
    if (event.target.id === 'empreendimento') setvEmpreendimento(res)
    if (event.target.id === 'endereco') setvEndereco(res)
    return res
  }
  function handleInput(event) {
    if (event.target.id === 'empreendimento') { setEmpreendimento(event.target.value) }
    if (event.target.id === 'construtora') { setConstrutora(event.target.value) }
    if (event.target.id === 'endereco') { setEndereco(event.target.value) }
    if (event.target.id === 'telefone') { setTelefone(event.target.value) }
    if (event.target.id === 'site') { setSite(event.target.value) }
    if (event.target.id === 'entrega') { setEntrega(event.target.value) }
    if (event.target.id === 'descricao') { setDescricao(event.target.value) }
    if (event.target.name === 'situacao') { setSituacao(event.target.value) }
    hasError(event)
  }

  function setPlace() {
    if (newPlace.id) _place.id = newPlace.id
    _place.position = position ? position : newPosition
    _place.empreendimento = empreendimento ? empreendimento : ''
    _place.construtora = construtora ? construtora : ''
    _place.endereco = endereco ? endereco : ''
    _place.telefone = telefone ? telefone : ''
    _place.site = site ? site : ''
    _place.entrega = entrega ? entrega : ''
    _place.situacao = situacao ? situacao : ''
    _place.descricao = descricao ? descricao : ''

  }

  return (
    <FormControl sx={{ width: '100%' }} onBlur={setPlace} >

      <Grid sx={{ display: 'flex' }} component={'span'}>
        <TextField sx={style}
          size='small'
          autoFocus
          required
          fullWidth
          id="empreendimento"
          name='empreendimento'
          label="Empreendimento"
          value={empreendimento}
          onBlur={hasError}
          onChange={handleInput}
          helperText="Nome do empreendimento"
          error={vEmpreendimento}
          inputProps={{ maxLength: 60 }}
        />

        <TextFieldMask sx={style}
          size='small'
          id="telefone"
          name='telefone'
          label="Telefone"
          value={telefone}
          onChange={handleInput}
          helperText="Telefone para contato"
          mask={'phone'}
        />
      </Grid>
      <Grid sx={{
        display: 'flex',
      }}
        component={'span'}>

        <TextField sx={style}
          size='small'
          required
          fullWidth
          id="construtora"
          name='construtora'
          label="Construtora"
          value={construtora}
          onChange={handleInput}
          onBlur={hasError}
          helperText="Construtora/Incorporadora"
          error={vConstrutora}
          inputProps={{ maxLength: 60 }}
        />

        <TextField sx={style}
          size='small'
          fullWidth
          id="site"
          name='site'
          label="Site"
          value={site}
          onChange={handleInput}
          helperText="Site do empreendimento"
        />
      </Grid>
      <TextField sx={style}
        size='small'
        required
        id="endereco"
        name='endereco'
        label="Endereço"
        value={endereco}
        onChange={handleInput}
        onBlur={hasError}
        helperText="Endereço do empreendimento"
        error={vEndereco}
        inputProps={{ maxLength: 100 }}
      />
      <Grid sx={{
        display: 'flex',

      }}
        component={'span'}>
        <TextField sx={style}
          size='small'
          fullWidth
          id="entrega"
          name='entrega'
          label="Entrega"
          value={entrega}
          onChange={handleInput}
          helperText="Prazos de Entrega"
          inputProps={{ maxLength: 50 }}
        />
        <FormControl sx={{ mt: 1, mr: 1, minWidth: 200 }}>
          <InputLabel id="lbSituacao" >Situação</InputLabel>
          <Select
            size='small'
            label='Situação'
            name='situacao'
            id="situacao"
            labelId="lbSituacao"
            value={situacao||'LA'}
            onChange={handleInput}

          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value={'LA'}>Lançamento</MenuItem>
            <MenuItem value={'EO'}>Em Obra</MenuItem>
            <MenuItem value={'OE'}>Em Obra/Entregue</MenuItem>
            <MenuItem value={'E'}>Entregue</MenuItem>
            <MenuItem value={'V'}>100% Vendido</MenuItem>
          </Select>
          <FormHelperText component={'span'}>Situação do empreendimento</FormHelperText>
        </FormControl>

      </Grid>
      <Grid sx={{ display: 'flex' }} component={'span'}>
        <TextField sx={style}
          size='small'
          fullWidth
          id="descricao"
          name='descricao'
          label="Descrição"
          multiline
          rows={7}
          value={descricao}
          onChange={handleInput}
          helperText="Descrição do empreedimento "
          inputProps={{ maxLength: 1000 }}
        />
      </Grid>
    </FormControl>
  )
}