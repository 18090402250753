
export function email(e) {
  let value = e.target.value;
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex)) {
    e.target.value = value
  }
  return e
}

export function cep(e) {
  let value = e.target.value;
  e.target.maxLength = 9
  value = value.replace(/\D/g, "")  // Remove o que não é numero
  value = value.replace(/^(\d{5})(\d)/, "$1-$2")
  e.target.value = value
  return e
}
export function convite(e) {
  let value = e.target.value.toUpperCase();
  e.target.maxLength = 9
  value = value.replace(/[^\w@-]/, "")
  value = value.replace(/(\w{4})([A-Z]|[0-9])/g, "$1-$2")
  e.target.value = value
  return e
}

export function mesano(e) {
  let value = e.target.value;
  e.target.maxLength = 5
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d)(\d{2})$/, "$1/$2")
  e.target.value = value.toUpperCase()
  return e.target.value
}


export function nrcreci(e) {
  let value = e.target.value;
  e.target.maxLength = 7
  value = value.replace(/[^a-zA-Z0-9]/, '')
  e.target.value = value.toUpperCase()
  return e.target.value
}

export function currency(e, moeda) {
  let value = e.target.value;
  e.target.maxLength = 16
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d)(\d{2})$/, "$1,$2")
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".")

  e.target.value = moeda + ' ' + value
  return e
}
export function cnpj(e) {
  let value = e.target.value;
  e.target.maxLength = 14
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  e.target.value = value
  return e.target.value
}

export function cpf(e) {
  let value = e.target.value;
  e.target.maxLength = 11
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  e.target.value = value
  return e.target.value
}

export function url(e) {
  let value = e.target.value;
  e.target.maxLength = 14
  value = value.replace("^(http|https?://(?:www.|(?!www))[^s.]+.[^s]{2,3}|www.[^s]+.[^s]{2,3})$")
  e.target.value = value
  return e.target.value
}

// (00) 00000-0000
export function phone(e) {
  let value = e.target.value;
  e.target.maxLength = 14
  value = value.replace(/\D/g, "")
  value = value.replace(/(\d{2})(\d)/, "($1) $2")
  value = value.replace(/(\d{5})(\d{4})(\d)/, "$1-$2")
  e.target.value = value
  return e.target.value
}
// AJUSTAR PARA FUNCTION() ----
// 00/00/0000 
export const date = value => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1")
}

// Aceita apenas que letras sejam digitadas
export const letters = value => {
  return value.replace(/[0-9!@#¨$%^&*)(+=._-]+/g, "")
}

// Aceita apenas números
export const numbers = value => {
  return value.replace(/\D/g, "")
}