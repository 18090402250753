import { useCallback } from 'react'

import { cep, convite, currency, phone, nrcreci, email, cnpj, cpf, mesano } from './Mask';

import './mask.css'
import { TextField } from '@mui/material';

export const TextFieldMask = ({ mask, moeda, ...props }) => {
  const handleKeyUp = useCallback((e) => {
    if (mask === 'cep') cep(e)
    if (mask === 'currency') currency(e, moeda)
    if (mask === 'phone') phone(e)
    if (mask === 'convite') convite(e)
    if (mask === 'nrcreci') nrcreci(e)
    if (mask === 'email') email(e)
    if (mask === 'cnpj') cnpj(e)
    if (mask === 'cpf') cpf(e)
    if (mask === 'mesano') mesano(e)
  }, [mask, moeda])

  return (
    <>
      <TextField {...props} onKeyUp={handleKeyUp} />
    </>
  )
}