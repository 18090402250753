export default function gerarCodigo() {
  const codigo = {
    codigo: String,
    validade: new Date()
  }

  let validade = new Date()
  let dias = 10
  const geraAlpha = () => {
    return String.fromCharCode(65 + Math.floor(Math.random() * 26))
  }
  const geraNumero = () => {
    return Math.floor(Math.random() * 10)
  }
  const reduce = Array(4).fill(0).reduce(prev => {
    return {
      alpha: prev.alpha + geraAlpha(),
      numero: prev.numero + geraNumero()
    }
  }, { alpha: '', numero: '' })
  codigo.codigo = `${reduce.alpha}-${reduce.numero}`
  codigo.validade = validade.setDate(validade.getDate() + dias)
  return codigo
}