import { createTheme, responsiveFontSizes } from '@mui/material';

export function AppTheme() {
  let theme = createTheme(
    {
      palette: {
        primary:{
          main: '#1D2332',
        },
        secondary:{
          main:'#8c9eff'
        }
      },
      typography: {
        fontSize: 11,
      },
      // breakpoints: {
      //   values: {
      //     xs: 0,
      //     sm: 600,
      //     md: 900,
      //     lg: 1200,
      //     xl: 1536,
      //   }
      // },
      tableRow: {
        "&:hover": {
          backgroundColor: "blue !important"
        }
      },
      MuiTableRow: {
        styleOverrides: {
          // Even though there is a hover rule we have to override it here. Don't ask.
          root: {
            '&.MuiTableRow-hover:hover': {
              backgroundColor: 'blue',
            },
          },
        },
      },
      

    }
  );
  return responsiveFontSizes(theme)
}