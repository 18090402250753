const ruleAdm = Object.freeze({
  id: 'adm',
  title: 'Administrador',
  permissions: ['read', 'write', 'delete', 'list']
})

const ruleOperator = Object.freeze({
  id: 'ope',
  title: 'Cadastro',
  permissions: ['read', 'write', 'delete', 'list']
})

const ruleViewer = Object.freeze({
  id: 'vie',
  title: 'Consulta',
  permissions: ['read', 'list']
})
const customerStatus = Object.freeze({
  ATV: 'ATV', // Ativo
  SPS: 'SPS', // Suspenso
  DEL: 'DEL', // Deletado
})

export const rules = Object.freeze({
  ruleAdm,
  ruleOperator,
  ruleViewer,
  customerStatus
})
