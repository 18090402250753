export class accountModel {
  constructor(
    id, email, nome, permissions, rules, conta, plano, photourl, token, emailVerified, hasinvite, equipe, contas, credito
  ) {
    this.id = id
    this.email = email
    this.nome = nome
    this.permissions = permissions
    this.rules = rules
    this.conta = conta
    this.plano = plano
    this.photourl = photourl
    this.token = token
    this.emailVerified = emailVerified
    this.hasinvite = hasinvite
    this.equipe = equipe
    this.contas = contas
    this.credito = credito
  }
  setData(data) {
    this.id = data.id
    this.email = data.email
    this.nome = data.nome
    this.permissions = data.permissions
    this.rules = data.rules
    this.conta = data.conta
    this.plano = data.plano
    this.photourl = data.photourl
    this.token = data.token
    this.emailVerified = data.emailVerified
    this.hasinvite = data.hasinvite
    this.equipe = data.equipe
    this.contas = data.contas
    this.credito = data.credito
  }
}