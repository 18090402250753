export function descDormitorios(_marker) {
  let qtdmin = _marker.quartosqtdmin 
  let qtdmax = _marker.quartosqtdmax 
  let _dormitorios = ''
  if ((qtdmin !== undefined) && (qtdmax !== undefined)) {
    if (qtdmin === qtdmax) {
      if (qtdmin === 0) { _dormitorios = 'Studio' }
      else if (qtdmin === 1) { _dormitorios = '01 quarto' }
      else { _dormitorios = `0${qtdmin} quartos` }
    }
    else if (qtdmin === 0) {
      _dormitorios = `Studio até 0${qtdmax} quarto(s)`
    }
    else {
      _dormitorios = `0${qtdmin} até 0${qtdmax} quartos`
    }
  }
  return _dormitorios
}