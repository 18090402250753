import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AppRoutes from './routes/AppRoutes';
import { AppTheme } from './theme/ThemeApp';
import './App.css';

require('./firebase')

function App() {

  const themeApp = AppTheme();
  return (
    <BrowserRouter>
      <ThemeProvider theme={themeApp}>
      <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
