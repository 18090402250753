export const Meses = [
  { mes:'01', descricao: 'Janeiro', sigla: 'Jan',  },
  { mes:'02', descricao: 'Fevereiro', sigla: 'Fev' },
  { mes:'03', descricao: 'Março', sigla: 'Mar' },
  { mes:'04', descricao: 'Abril', sigla: 'Abr' },
  { mes:'05', descricao: 'Maio', sigla: 'Mai' },
  { mes:'06', descricao: 'Junho', sigla: 'Jun' },
  { mes:'07', descricao: 'Julho', sigla: 'Jul' },
  { mes:'08', descricao: 'Agosto', sigla: 'Ago' },
  { mes:'09', descricao: 'Setembro', sigla: 'Set' },
  { mes:'10', descricao: 'Outubro', sigla: 'Out' },
  { mes:'11', descricao: 'Novembro', sigla: 'Nov' },
  { mes:'12', descricao: 'Dezembro', sigla: 'Dez' },
]