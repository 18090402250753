import { createUserWithEmailAndPassword, deleteUser, sendEmailVerification, updateCurrentUser } from 'firebase/auth'
import { auth, db } from '../firebase'
import { addDoc, collection, doc, runTransaction, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore'
import { userConverter } from '../models/userModel'
import { result } from '../utils/result'
import { getContaConvite } from './contaController'
import { rules } from '../constants/rules'
import { apiGate } from '../services/api_gate'
import { plans } from '../constants/plans'
import { getCanJoinTeam } from './equipeController'

export async function userCreateNew(password, usuario, team) {
  let newUserCredendial = null
  try {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou no userCreateNew', usuario.email, password)

    newUserCredendial = await createUserWithEmailAndPassword(auth, usuario.email, password)

    // Verificar se o usuário possuiu assinatura válida
    // Se tiver:
    // Para assinatura USER, negar cadastro se não possuir conta de terceiro.

    const subscription = await (await apiGate.get('/clients/licenca/' + usuario.email)).data
    if (subscription) {
      usuario.plano = subscription.licenca
      usuario.credito = subscription.credito
      if (subscription.licenca === plans.tpLicenca.USER) {
        if (!usuario.convite) {
          result.sucess = false
          result.msg = 'Assinatura USER requer um código de convite para cadastramento. Solicite um código para o administrador da equipe.'
          result.data = subscription
          await deleteUserAuth(newUserCredendial.user) // Deleta o usuário no Google Auth
          return result
        }
      }
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('usuario Inscrito', usuario)
    }

    // Processo com inconsistência devido a consulta realizada por usuário ainda não autenticado
    // Mover para momento posterior a autenticação dentro de userSingIn em addNewUser

    // Talvez o updateCurrentUser resolva o problema
    await auth.updateCurrentUser(newUserCredendial.user)

    // Busca conta de equipe para usuário integrante de equipe  
    let contas = ''
    if (team && usuario.convite) {
      contas = await getContaConvite(usuario.convite)
      if (contas.length > 0) {
        if (contas.length === 1) {  // Um convite existente
          const rst = await getCanJoinTeam(contas[0])
          if (rst.sucess) {
            usuario.conta = contas[0]
          }
          else {
            await deleteUserAuth(newUserCredendial.user) // Deleta o usuário no Google Auth
            return rst
          }
        }
        else { // Múltiplos convites com o mesmo código
          result.sucess = false
          result.msg = 'Solicite um novo código de convite para o administrador da equipe!'
          result.data = usuario
          await deleteUserAuth(newUserCredendial.user) // Deleta o usuário no Google Auth
          return result
        }
      }
      else {
        result.sucess = false
        result.msg = 'Código de equipe inválido, solicite um novo código de convite para o administrador da equipe!'
        result.data = usuario
        await deleteUserAuth(newUserCredendial.user) // Deleta o usuário no Google Auth
        return result
      }
    }

    usuario.msg += ' - Credential Ok!'
    usuario.token = newUserCredendial.user.accessToken
    usuario.emailVerified = newUserCredendial.user.emailVerified

    await runTransaction(db, async (transaction) => {
      // Cria usuario
      const userColRef = collection(db, 'usuarios').withConverter(userConverter)
      const docRef = await addDoc(userColRef, usuario)
      usuario.id = docRef.id.toString();

      // Usuario Novo com conta de terceiros EQUIPE, possui conta na Origem.
      if (usuario.conta) {
        // Atualiza id do usuario Operador
        await updateDoc(doc(db, 'usuarios', usuario.id), { id: usuario.id, conta: [usuario.conta] })
        // Cadastra Operador em Conta de Terceiro - Equipe
        const rst = await saveOperador(usuario, usuario.conta)
        if (!rst.sucess) {
          await deleteUserAuth(newUserCredendial.user) // Deleta o usuário no Google Auth
          return rst // Se erro retorna ao formulário
        }
      }
      else {// Usuario Administrador de Conta
        // Atualiza ID e a Conta do usuario Administrador
        await updateDoc(doc(db, 'usuarios', usuario.id), { id: usuario.id, conta: [usuario.id] })
        usuario.conta = usuario.id
        //Cria uma conta de trabalho para o usuario Administrador 
        await setContaTrabalho(usuario)
      }
      usuario.msg = 'Cadastro Ok!'
      result.sucess = true
      result.msg = 'Operação realizada com sucesso.'
      result.data = usuario
    })

    // Envia email de verificação
    await sendEmailVerification(newUserCredendial.user).then(() => {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Email de verificação enviado com sucesso...')
    })
  }
  catch (error) {
    const errorCode = error;
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em userCreateNew', error)
    usuario.id = null
    usuario.msg = errorCode
    result.sucess = false
    result.msg = errorCode.message
    result.data = usuario
    await deleteUserAuth(newUserCredendial.user)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saindo de userCreateNew', result)
    return result
  }
}

async function deleteUserAuth(credential) {
  await deleteUser(credential).then(
    result.msg += '-> ...'
  ).catch((error) => {
    result.sucess = false
    result.msg += '-> [' + error + ']'
  })
}

async function saveOperador(_operador, _conta) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em saveOperador:', _operador)
  try {
    const opConta = {
      id: _operador.id,
      nome: _operador.nome,
      email: _operador.email,
      rules: [rules.ruleViewer.id],
      permissions: rules.ruleViewer.permissions,
      ativo: true,
      createdat: serverTimestamp()
    }
    const docRefOperador = doc(db, 'contas/' + _conta + '/operadores/' + _operador.id)
    await setDoc(docRefOperador, opConta)
    result.sucess = true
    result.msg = 'Operador cadastrado com sucesso...'
    result.data = null
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na operação de cadastro...'
    result.data = null
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setOperador:', _operador, e)
  }
  finally {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de setOperador:', result)
    return result
  }

}
async function setContaTrabalho(usuario) {
  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em setContaTrabalho', usuario)
  const tConta = {
    email: usuario.email,
    convite: null,
    conviteval: null,
    rules: [rules.ruleAdm.title],
    permissions: rules.ruleAdm.permissions,
    ativo: true,
    createdat: serverTimestamp(),
    equipe: usuario.email
  }
  try {
    await setDoc(doc(db, 'contas', usuario.id), tConta)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Conta Traballho Ok!', tConta)
  }
  catch (e) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em setContaTrabalho!', e)
  }
}