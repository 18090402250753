import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'

export const Caracteristicas = ({
  setPlaceEdit, placeEdit,
  setNewPlace, newPlace }) => {

  let _place = placeEdit


  const [quartosqtdmin, setQuartosQtdMin] = useState(_place.hasOwnProperty('quartosqtdmin') ? _place.quartosqtdmin : newPlace?.quartosqtdmin )
  const [quartosqtdmax, setQuartosQtdMax] = useState(_place.hasOwnProperty('quartosqtdmax') ? _place.quartosqtdmax : newPlace?.quartosqtdmax )
  const [minarea, setMinarea] = useState(_place.hasOwnProperty('minarea') ? _place.minarea : newPlace.minarea)
  const [maxarea, setMaxarea] = useState(_place.hasOwnProperty('maxarea') ? _place.maxarea : newPlace.maxarea)
  const [andares, setAndares] = useState(_place.hasOwnProperty('andares') ? _place.andares : newPlace.andares)
  const [blocos, setBlocos] = useState(_place.hasOwnProperty('blocos') ? _place.blocos : newPlace.blocos)
  const [sacada, setSacada] = useState(_place.hasOwnProperty('sacada') ? _place.sacada : newPlace.sacada)
  const [elevador, setElevador] = useState(_place.hasOwnProperty('elevador') ? _place.elevador : newPlace.elevador)
  const [piscina, setPiscina] = useState(_place.hasOwnProperty('piscina') ? _place.piscina : newPlace.piscina)
  const [salafesta, setSalaFesta] = useState(_place.hasOwnProperty('salafesta') ? _place.salafesta : newPlace.salafesta)
  const [churrasqueira, setChurrasqueira] = useState(_place.hasOwnProperty('churrasqueira') ? _place.churrasqueira : newPlace.churrasqueira)
  const [quiosquechur, setQuiosquechur] = useState(_place.hasOwnProperty('quiosquechur') ? _place.quiosquechur : newPlace.quiosquechur)
  const [playgroud, setPlaygroud] = useState(_place.hasOwnProperty('playgroud') ? _place.playgroud : newPlace.playgroud)
  const [espacokids, setEspacokids] = useState(_place.hasOwnProperty('espacokids') ? _place.espacokids : newPlace.espacokids)
  const [salajogos, setSalajogos] = useState(_place.hasOwnProperty('salajogos') ? _place.salajogos : newPlace.salajogos)
  const [gourmet, setGourmet] = useState(_place.hasOwnProperty('gourmet') ? _place.gourmet : newPlace.gourmet)
  const [vagafixa, setVagaFixa] = useState(_place.hasOwnProperty('vagafixa') ? _place.vagafixa : newPlace.vagafixa)
  const [vagarotativa, setVagaRotativa] = useState(_place.hasOwnProperty('vagarotativa') ? _place.vagarotativa : newPlace.vagarotativa)
  const [gardem, setGardem] = useState(_place.hasOwnProperty('gardem') ? _place.gardem : newPlace.gardem)
  const [academia, setAcademia] = useState(_place.hasOwnProperty('academia') ? _place.academia : newPlace.academia)
  const [qdesportiva, setQdEsportiva] = useState(_place.hasOwnProperty('qdesportiva') ? _place.qdesportiva : newPlace.qdesportiva)
  const [qdtenis, setQdTenis] = useState(_place.hasOwnProperty('qdtenis') ? _place.qdtenis : newPlace.qdtenis)
  const [qdvolei, setQdVolei] = useState(_place.hasOwnProperty('qdvolei') ? _place.qdvolei : newPlace.qdvolei)
  const [esportebar, setEsporteBar] = useState(_place.hasOwnProperty('esportebar') ? _place.esportebar : newPlace.esportebar)
  const [petcare, setPetCare] = useState(_place.hasOwnProperty('petcare') ? _place.petcare : newPlace.petcare)
  const [petplace, setPetPlace] = useState(_place.hasOwnProperty('petplace') ? _place.petplace : newPlace.petplace)
  const [salamult, setSalaMult] = useState(_place.hasOwnProperty('salamult') ? _place.salamult : newPlace.salamult)
  const [coworking, setCoworking] = useState(_place.hasOwnProperty('coworking') ? _place.coworking : newPlace.coworking)
  const [marketplace, setMarketplace] = useState(_place.hasOwnProperty('marketplace') ? _place.marketplace : newPlace.marketplace)
  const [lavanderia, setLavanderia] = useState(_place.hasOwnProperty('lavanderia') ? _place.lavanderia : newPlace.lavanderia)
  const [sauna, setSauna] = useState(_place.hasOwnProperty('sauna') ? _place.sauna : newPlace.sauna)
  const [rooftop, setRooftop] = useState(_place.hasOwnProperty('rooftop') ? _place.rooftop : newPlace.rooftop)
  const [cinema, setCinema] = useState(_place.hasOwnProperty('cinema') ? _place.cinema : newPlace.cinema)


  const handleInput = (event) => {

    if (event.target.id === 'quartosqtdmin') { setQuartosQtdMin(event.target.value) }
    if (event.target.id === 'quartosqtdmax') { setQuartosQtdMax(event.target.value) }
    if (event.target.id === 'minarea') { setMinarea(event.target.value) }
    if (event.target.id === 'minarea') { setMinarea(event.target.value) }
    if (event.target.id === 'maxarea') { setMaxarea(event.target.value) }
    if (event.target.id === 'andares') { setAndares(event.target.value) }
    if (event.target.id === 'blocos') { setBlocos(event.target.value) }
    if (event.target.id === 'sacada') { setSacada(Boolean(event.target.checked)) }
    if (event.target.id === 'elevador') { setElevador(Boolean(event.target.checked)) }
    if (event.target.id === 'piscina') { setPiscina(Boolean(event.target.checked)) }
    if (event.target.id === 'salafesta') { setSalaFesta(Boolean(event.target.checked)) }
    if (event.target.id === 'churrasqueira') { setChurrasqueira(Boolean(event.target.checked)) }
    if (event.target.id === 'quiosquechur') { setQuiosquechur(Boolean(event.target.checked)) }
    if (event.target.id === 'playgroud') { setPlaygroud(Boolean(event.target.checked)) }
    if (event.target.id === 'espacokids') { setEspacokids(Boolean(event.target.checked)) }
    if (event.target.id === 'salajogos') { setSalajogos(Boolean(event.target.checked)) }
    if (event.target.id === 'gourmet') { setGourmet(Boolean(event.target.checked)) }
    if (event.target.id === 'vagafixa') { setVagaFixa(Boolean(event.target.checked)) }
    if (event.target.id === 'vagarotativa') { setVagaRotativa(Boolean(event.target.checked)) }
    if (event.target.id === 'gardem') { setGardem(Boolean(event.target.checked)) }
    if (event.target.id === 'academia') { setAcademia(Boolean(event.target.checked)) }
    if (event.target.id === 'qdesportiva') { setQdEsportiva(Boolean(event.target.checked)) }
    if (event.target.id === 'qdtenis') { setQdTenis(Boolean(event.target.checked)) }
    if (event.target.id === 'qdvolei') { setQdVolei(Boolean(event.target.checked)) }
    if (event.target.id === 'esportebar') { setEsporteBar(Boolean(event.target.checked)) }
    if (event.target.id === 'petcare') { setPetCare(Boolean(event.target.checked)) }
    if (event.target.id === 'petplace') { setPetPlace(Boolean(event.target.checked)) }
    if (event.target.id === 'salamult') { setSalaMult(Boolean(event.target.checked)) }
    if (event.target.id === 'coworking') { setCoworking(Boolean(event.target.checked)) }
    if (event.target.id === 'marketplace') { setMarketplace(Boolean(event.target.checked)) }
    if (event.target.id === 'lavanderia') { setLavanderia(Boolean(event.target.checked)) }
    if (event.target.id === 'sauna') { setSauna(Boolean(event.target.checked)) }
    if (event.target.id === 'rooftop') { setRooftop(Boolean(event.target.checked)) }
    if (event.target.id === 'cinema') { setCinema(Boolean(event.target.checked)) }

  }

  function setPlace() {
    _place.quartosqtdmin = quartosqtdmin 
    _place.quartosqtdmax = quartosqtdmax 
    _place.minarea = minarea || ''
    _place.maxarea = maxarea || ''
    _place.andares = andares || ''
    _place.blocos = blocos || ''
    _place.sacada = sacada || false
    _place.elevador = elevador || false
    _place.piscina = piscina || false
    _place.salafesta = salafesta || false
    _place.churrasqueira = churrasqueira || false
    _place.quiosquechur = quiosquechur || false
    _place.playgroud = playgroud || false
    _place.espacokids = espacokids || false
    _place.salajogos = salajogos || false
    _place.gourmet = gourmet || false
    _place.vagafixa = vagafixa || false
    _place.vagarotativa = vagarotativa || false
    _place.gardem = gardem || false
    _place.academia = academia || false
    _place.qdesportiva = qdesportiva || false
    _place.qdtenis = qdtenis || false
    _place.qdvolei = qdvolei || false
    _place.esportebar = esportebar || false
    _place.petcare = petcare || false
    _place.petplace = petplace || false
    _place.salamult = salamult || false
    _place.coworking = coworking || false
    _place.marketplace = marketplace || false
    _place.lavanderia = lavanderia || false
    _place.sauna = sauna || false
    _place.rooftop = rooftop || false
    _place.cinema = cinema || false
  }

  const styleRow = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    my: 1
  }

  const styleCol = {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',

  }

  const styleRow2 = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }

  const handleQtdMinQuartosChange = (event) => {
    if (quartosqtdmax === undefined || (Number(event.target.value) >= Number(quartosqtdmax))) {
      setQuartosQtdMax(event.target.value)
      newPlace.quartosqtdmax= event.target.value
    } 
    setQuartosQtdMin(event.target.value)
  }

  const handleQtdMaxQuartosChange = (event) => {
    // console.log(event.target.value, quartosqtdmin, quartosqtdmax)
    if (quartosqtdmin === undefined || (Number(event.target.value) <= Number(quartosqtdmin)) ) {
      setQuartosQtdMin(event.target.value)
      newPlace.quartosqtdmin= event.target.value
    }
    setQuartosQtdMax(event.target.value)
  }


  return (
    <FormControl sx={{ width: '100%' }} onBlur={setPlace} >
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={styleCol}>

            <Box sx={styleRow} >
              <FormControl sx={{ minWidth: '110px', mr: 1 }}>
                <InputLabel id="lbQuartos">De X Quartos</InputLabel>
                <Select
                  size='small'
                  labelId="lbQuartos"
                  id="quartosqtdmin"
                  name='quartosqtdmin'
                  value={quartosqtdmin}
                  label="De X Quartos"
                  onChange={handleQtdMinQuartosChange}
                  onBlur={handleInput}
                >
                  <MenuItem value={-1}></MenuItem>
                  <MenuItem value={0}>Studio</MenuItem>
                  <MenuItem value={1}>1 Quarto </MenuItem>
                  <MenuItem value={2}>2 Quartos</MenuItem>
                  <MenuItem value={3}>3 Quartos</MenuItem>
                  <MenuItem value={4}>4 Quartos</MenuItem>
                  <MenuItem value={5}>5 Quartos</MenuItem>
                  <MenuItem value={6}>+5 Quartos</MenuItem>
                </Select>
                {/* <FormHelperText>De X quartos</FormHelperText> */}
              </FormControl>
              <FormControl sx={{ minWidth: '110px', mr: 1 }}>
                <InputLabel id="lbQuartos">Até X Quartos</InputLabel>
                <Select
                  size='small'
                  labelId="lbQuartos"
                  id="quartosqtdmax"
                  name='quartosqtdmax'
                  value={quartosqtdmax}
                  label="Até X Quartos"
                  onChange={handleQtdMaxQuartosChange}
                  onBlur={handleInput}
                >
                  <MenuItem value={-1}></MenuItem>
                  <MenuItem value={0}>Studio</MenuItem>
                  <MenuItem value={1}>1 Quarto </MenuItem>
                  <MenuItem value={2}>2 Quartos</MenuItem>
                  <MenuItem value={3}>3 Quartos</MenuItem>
                  <MenuItem value={4}>4 Quartos</MenuItem>
                  <MenuItem value={5}>5 Quartos</MenuItem>
                  <MenuItem value={6}>+5 Quartos</MenuItem>
                </Select>
                {/* <FormHelperText>Até X quartos</FormHelperText> */}
              </FormControl>
              {/* </Box>
            <Box sx={styleRow} > */}
              <TextField sx={{ mr: 1, minWidth: '70px' }}
                size='small'
                id="minarea"
                name='minarea'
                label="De X Mts2"
                type="number"
                value={minarea}
                onChange={handleInput}
                //defaultValue={_place.minarea}
                // onBlur={handleDados}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                inputProps={{ maxLength: 3 }}
              />
              {/* </FormControl> */}
              <TextField sx={{ mr: 1, minWidth: '70px' }}
                size='small'
                id="maxarea"
                name='maxarea'
                label="Até X Mts2"
                type="number"
                value={maxarea}
                onChange={handleInput}
              // defaultValue={_place.maxarea}
              // onBlur={handleDados}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              />
              {/* </Box>

            <Box sx={styleRow} > */}
              <TextField sx={{ mr: 1, minWidth: '60px' }}
                size='small'
                id="andares"
                name='andares'
                label="Andares"
                type="number"
                value={andares}
                onChange={handleInput}
                // defaultValue={_place.andares}
                // onBlur={handleDados}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField sx={{ mr: 1, minWidth: '55px' }}
                size='small'
                id="blocos"
                name='blocos'
                label="Torres"
                type="number"
                value={blocos}
                onChange={handleInput}
                // defaultValue={_place.blocos}
                // onBlur={handleDados}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Divider sx={{ m: 1 }} />
            <FormControl onChange={handleInput}>
              <Box sx={styleRow2} justifyContent={'space-between'}>
                <Box sx={styleCol}>
                  <FormControlLabel control={<Checkbox checked={sacada} id='sacada' name='sacada' />} label="Sacada" />
                  <FormControlLabel control={<Checkbox checked={elevador} id='elevador' name='elevador' />} label="Elevador" />
                  <FormControlLabel control={<Checkbox checked={piscina} id='piscina' name='piscina' />} label="Piscina" />
                  <FormControlLabel control={<Checkbox checked={salafesta} id='salafesta' name='salafesta' />} label="Salão de Festa" />
                  <FormControlLabel control={<Checkbox checked={churrasqueira} id='churrasqueira' name='churrasqueira' />} label="Churrasqueira Apto" />
                  <FormControlLabel control={<Checkbox checked={quiosquechur} id='quiosquechur' name='quiosquechur' />} label="Quiosque c/ Churrasqueira" />
                  <FormControlLabel control={<Checkbox checked={playgroud} id='playgroud' name='playgroud' />} label="Playgroud" />
                  <FormControlLabel control={<Checkbox checked={espacokids} id='espacokids' name='espacokids' />} label="Espaço Kids" />
                  <FormControlLabel control={<Checkbox checked={salajogos} id='salajogos' name='salajogos' />} label="Sala de Jogos" />
                  <FormControlLabel control={<Checkbox checked={gourmet} id='gourmet' name='gourmet' />} label="Espaço Gourmet" />
                </Box>

                <Box sx={styleCol} >
                  <FormControlLabel control={<Checkbox checked={vagafixa} id='vagafixa' name='vagafixa' />} label="Vaga Fixa" />
                  <FormControlLabel control={<Checkbox checked={vagarotativa} id='vagarotativa' name='vagarotativa' />} label="Vaga Rotativa" />
                  <FormControlLabel control={<Checkbox checked={gardem} id='gardem' name='gardem' />} label="Garden" />
                  <FormControlLabel control={<Checkbox checked={academia} id='academia' name='academia' />} label="Academia" />
                  <FormControlLabel control={<Checkbox checked={qdesportiva} id='qdesportiva' name='qdesportiva' />} label="Quadra Esportiva" />
                  <FormControlLabel control={<Checkbox checked={qdtenis} id='qdtenis' name='qdtenis' />} label="Quadra de Tênis" />
                  <FormControlLabel control={<Checkbox checked={qdvolei} id='qdvolei' name='qdvolei' />} label="Quadra de Vôlei" />
                  <FormControlLabel control={<Checkbox checked={esportebar} id='esportebar' name='esportebar' />} label="Esporte Bar" />
                </Box>
                <Box sx={styleCol} >
                  <FormControlLabel control={<Checkbox checked={petcare} id='petcare' name='petcare' />} label="Pet Care" />
                  <FormControlLabel control={<Checkbox checked={petplace} id='petplace' name='petplace' />} label="Pet Place" />
                  <FormControlLabel control={<Checkbox checked={salamult} id='salamult' name='salamult' />} label="Sala Multiuso" />
                  <FormControlLabel control={<Checkbox checked={coworking} id='coworking' name='coworking' />} label="Coworking" />
                  <FormControlLabel control={<Checkbox checked={marketplace} id='marketplace' name='marketplace' />} label="Market Place" />
                  <FormControlLabel control={<Checkbox checked={lavanderia} id='lavanderia' name='lavanderia' />} label="Lavanderia" />
                  <FormControlLabel control={<Checkbox checked={sauna} id='sauna' name='sauna' />} label="Sauna" />
                  <FormControlLabel control={<Checkbox checked={rooftop} id='rooftop' name='rooftop' />} label="Rooftop" />
                  <FormControlLabel control={<Checkbox checked={cinema} id='cinema' name='cinema' />} label="Cinema" />
                </Box>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </FormControl>
  )
}