
export class Place {
  constructor(idcad, empreendimento, construtora, endereco,
    position, telefone, site,
    entrega, situacao, descricao,
    renda, vlrinicial, vlrfinal, vlrrefmesano,
    promocao, quartosqtdmin, quartosqtdmax,
    minarea, maxarea, andares, blocos,
    sacada, elevador, piscina,
    salafesta, churrasqueira, quiosquechur,
    playgroud, espacokids, salajogos,
    gourmet, vagafixa, vagarotativa,
    gardem, academia, qdesportiva,
    qdtenis, qdvolei, esportebar,
    petcare, petplace, salamult,
    coworking, marketplace, lavanderia,
    sauna, rooftop, cinema,
    urlimagem, drive, tbvendas,
    plantas, memorial, implantacao, divulgacao,
    createdat, refvlrmes, refvlrano
  ) {
    this.idcad = idcad
    this.empreendimento = empreendimento
    this.construtora = construtora
    this.endereco = endereco
    this.position = position
    this.telefone = telefone
    this.site = site
    this.entrega = entrega
    this.situacao = situacao
    this.descricao = descricao
    this.renda = renda
    this.vlrinicial = vlrinicial
    this.vlrfinal = vlrfinal
    this.vlrrefmesano = vlrrefmesano
    this.promocao = promocao
    this.quartosqtdmin = quartosqtdmin
    this.quartosqtdmax = quartosqtdmax
    this.minarea = minarea
    this.maxarea = maxarea
    this.andares = andares
    this.blocos = blocos
    this.sacada = sacada
    this.elevador = elevador
    this.piscina = piscina
    this.salafesta = salafesta
    this.churrasqueira = churrasqueira
    this.quiosquechur = quiosquechur
    this.playgroud = playgroud
    this.espacokids = espacokids
    this.salajogos = salajogos
    this.gourmet = gourmet
    this.vagafixa = vagafixa
    this.vagarotativa = vagarotativa
    this.gardem = gardem
    this.academia = academia
    this.qdesportiva = qdesportiva
    this.qdtenis = qdtenis
    this.qdvolei = qdvolei
    this.esportebar = esportebar
    this.petcare = petcare
    this.petplace = petplace
    this.salamult = salamult
    this.coworking = coworking
    this.marketplace = marketplace
    this.lavanderia = lavanderia
    this.sauna = sauna
    this.rooftop = rooftop
    this.cinema = cinema
    this.urlimagem = urlimagem
    this.drive = drive
    this.tbvendas = tbvendas
    this.plantas = plantas
    this.memorial = memorial
    this.implantacao = implantacao
    this.divulgacao = divulgacao
    this.createdat = createdat
    this.refvlrmes = refvlrmes
    this.refvlrano = refvlrano
  }
  toString() {
    return this.idcad + ', ' + this.empreendimento + ', ' + this.construtora + ', ' + this.endereco +
      ', ' + this.position + ', ' + this.telefone + ', ' + this.site +
      ', ' + this.entrega + ', ' + this.situacao + ', ' + this.descricao +
      ', ' + this.renda + ', ' + this.vlrinicial + ', ' + this.vlrfinal + ', ' + this.vlrrefmesano +
      ', ' + this.promocao + ', ' + this.quartosqtdmin + ', ' + this.quartosqtdmax +
      ', ' + this.minarea + ', ' + this.maxarea +
      ', ' + this.andares + ', ' + this.blocos + ', ' + this.sacada +
      ', ' + this.elevador + ', ' + this.piscina + ', ' +
      ', ' + this.salafesta + ', ' + this.churrasqueira + ', ' + this.quiosquechur +
      ', ' + this.playgroud + ', ' + this.espacokids + ', ' + this.salajogos +
      ', ' + this.gourmet + ', ' + this.vagafixa + ', ' + this.vagarotativa +
      ', ' + this.gardem + ', ' + this.academia + ', ' + this.qdesportiva +
      ', ' + this.qdtenis + ', ' + this.qdvolei + ', ' + this.esportebar +
      ', ' + this.petcare + ', ' + this.petplace + ', ' + this.salamult +
      ', ' + this.coworking + ', ' + this.marketplace + ', ' + this.lavanderia +
      ', ' + this.sauna + ', ' + this.rooftop + ', ' + this.cinema +
      ', ' + this.urlimagem + ', ' + this.drive + ', ' + this.tbvendas +
      ', ' + this.plantas + ', ' + this.memorial + ', ' + this.implantacao +
      ', ' + this.divulgacao + ', ' + this.createdat +
      ', ' + this.refvlrmes + ', ' + this.refvlrano

  }
}

// Firestore data converter
export const placeConverter = {
  toFirestore: (place) => {
    return {
      idcad: place.idcad,
      empreendimento: place.empreendimento,
      construtora: place.construtora,
      endereco: place.endereco,
      position: place.position,
      telefone: place.telefone ? place.telefone : '',
      site: place.site ? place.site : '',
      entrega: place.entrega ? place.entrega : '',
      situacao: place.situacao ? place.situacao : '',
      descricao: place.descricao ? place.descricao : '',
      renda: place.renda ? place.renda : '',
      vlrinicial: place.vlrinicial ? place.vlrinicial : '',
      vlrfinal: place.vlrfinal ? place.vlrfinal : '',
      vlrrefmesano: place.vlrrefmesano ? place.vlrrefmesano : '',
      promocao: place.promocao ? place.promocao : '',
      quartosqtdmin: place.quartosqtdmin ? place.quartosqtdmin : 0,
      quartosqtdmax: place.quartosqtdmax ? place.quartosqtdmax : 0,
      minarea: place.minarea ? place.minarea : '',
      maxarea: place.maxarea ? place.maxarea : '',
      andares: place.andares ? place.andares : '',
      blocos: place.blocos ? place.blocos : '',

      sacada: place.sacada ? place.sacada : false,
      elevador: place.elevador ? place.elevador : false,
      piscina: place.piscina ? place.piscina : false,
      salafesta: place.salafesta ? place.salafesta : false,
      churrasqueira: place.churrasqueira ? place.churrasqueira : false,
      quiosquechur: place.playgroud ? place.playgroud : false,
      playgroud: place.playgroud ? place.playgroud : false,
      espacokids: place.espacokids ? place.espacokids : false,
      salajogos: place.salajogos ? place.salajogos : false,
      gourmet: place.gourmet ? place.gourmet : false,
      vagafixa: place.vagafixa ? place.vagafixa : false,
      vagarotativa: place.vagarotativa ? place.vagarotativa : false,
      gardem: place.gardem ? place.gardem : false,
      academia: place.academia ? place.academia : false,
      qdesportiva: place.qdesportiva ? place.qdesportiva : false,
      qdtenis: place.qdtenis ? place.qdtenis : false,
      qdvolei: place.qdvolei ? place.qdvolei : false,
      esportebar: place.esportebar ? place.esportebar : false,
      petcare: place.petcare ? place.petcare : false,
      petplace: place.petplace ? place.petplace : false,
      salamult: place.salamult ? place.salamult : false,
      coworking: place.coworking ? place.coworking : false,
      marketplace: place.marketplace ? place.marketplace : false,
      lavanderia: place.lavanderia ? place.lavanderia : false,
      sauna: place.sauna ? place.sauna : false,
      rooftop: place.rooftop ? place.rooftop : false,
      cinema: place.cinema ? place.cinema : false,

      urlimagem: place.urlimagem ? place.urlimagem : '',
      drive: place.drive ? place.drive : '',
      tbvendas: place.tbvendas ? place.tbvendas : '',
      plantas: place.plantas ? place.plantas : '',
      memorial: place.memorial ? place.memorial : '',
      implantacao: place.implantacao ? place.implantacao : '',
      divulgacao: place.divulgacao ? place.divulgacao : '',
      createdat: place.createdat ? place.createdat : '',
      refvlrmes: place.refvlrmes ? place.refvlrmes : '',
      refvlrano: place.refvlrano ? place.refvlrano : ''

    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Place(
      data.idcad,
      data.empreendimento, data.construtora, data.endereco,
      data.position, data.telefone, data.site,
      data.entrega, data.situacao, data.descricao,
      data.renda, data.vlrinicial, data.vlrfinal, data.vlrrefmesano,
      data.promocao, data.quartosqtdmin, data.quartosqtdmax,
      data.minarea, data.maxarea, data.andares, data.blocos,
      data.sacada, data.elevador, data.piscina,
      data.salafesta, data.churrasqueira, data.quiosquechur,
      data.playgroud, data.espacokids, data.salajogos,
      data.gourmet, data.vagafixa, data.vagarotativa,
      data.gardem, data.academia, data.qdesportiva,
      data.qdtenis, data.qdvolei, data.esportebar,
      data.petcare, data.petplace, data.salamult,
      data.coworking, data.marketplace, data.lavanderia,
      data.sauna, data.rooftop, data.cinema,
      data.urlimagem, data.drive, data.tbvendas,
      data.plantas, data.memorial, data.implantacao, data.divulgacao,
      data.createdat, data.refvlrmes, data.refvlrano
    );
  }
};
